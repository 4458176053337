const initialState = {
  tenant: null,
};

export const TenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TENANT':
      return { ...state, tenant: action.payload };
    default:
      return state;
  }
};
