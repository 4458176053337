const GET_LIST = {
  query: `
      query($query: QueryInput) {
        issuerUsers(query: $query) {
          items {
            id
            tenantId
            issuerCompany {
              id
              name
            }
            firstName
            lastName
            order
            email
            position
            biography
            isManagementUserApproved
            isUserDisabled
            sendInvite
          }
          total
        }
      }
    `,
  parseResult: ({ data }) => ({
    data: data.issuerUsers.items,
    total: data.issuerUsers.total,
  }),
};

export default {
  GET_LIST,

  GET_MANY: GET_LIST,

  GET_ONE: {
    query: `
        query($id: ID!) {
          issuerUser(_id: $id) {
            id
            tenantId
            issuerCompanyId
            issuerCompany {
              id
              name
            }
            firstName
            lastName
            email
            position
            imageId
            biography
            isManagementUserApproved
            isUserDisabled
            sendInvite
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.issuerUser }),
  },

  CREATE: {
    mutation: `
        mutation($data: IssuerUserInput!) {
          createIssuerUser(data: $data) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.createIssuerUser }),
  },

  UPDATE: {
    mutation: `
        mutation($id: ID!, $data: IssuerUserInput!, $previousData: IssuerUserInput!) {
          updateIssuerUser(_id: $id, data: $data, previousData: $previousData) {
            id
          }
        }
      `,
    excluded: ['issuerCompany'],
    parseResult: ({ data }) => ({ data: data.updateIssuerUser }),
  },

  DELETE: {
    mutation: `
        mutation($id: ID!) {
          removeIssuerUser(_id: $id) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.removeIssuerUser }),
  },
};
