const GET_LIST = {
  query: `
      query($query: QueryInput) {
        offers(query: $query) {
          items {
            id
            requireApproval
            approved
            tenantId
            archived
            order
            workspaceId
            headerType
            headerVideoId 
            name
            status
            typeOfOffer
            processType
            republicProcessType
            republicUrl
            wefunderProcessType
            wefunderUrl
            realexProcessType
            realexUrl
            offerPassword
            visibility
            dynamicsProjectId
            issuerCompany {
              id
              name
            }
            listingType
            sector
            investmentInstrument
            area
            minimumInvestment
            targetRaise
            sharesForSale
            highlights
            primaryObjective
            county
            countDown
            rssFeeds
            tenantHasDocusign
            isVanityUrl
            vanityUrl
            baseCurrency
            geographicMandate
            fundType
            domicileOption
            domicileOnshore
            domicileOffShore
            systematicDiscretionaryOption
            investmentCoActExemptionOption
            investmentAdvisorType
            leverage
            fundStrategyClassfication
            fundStrategyClassficationOne
            fundStrategyClassficationTwo
            fundStrategyClassficationThree
            aumfund
            fundCapacity
            trackRecordFund
            trackRecordFirm
            fundtype_other
            graphicmandate_other
            basecurrency_other
            domicileOnshoreFund_other
            domicileOffShore_other
            fundStructure
            openEndFund
            redemption
            lockupPeriod
            fundtype_other
            redemption_other
            exemption
            investmentAdvisorType_state
            foreign
            requestApprovalIssuer
            issuerOfferApprovalStatus
            altOfferTitle
            calendlyUrl
          }
          total
        }
      }
    `,
  parseResult: ({ data }) => ({
    data: data.offers.items,
    total: data.offers.total,
  }),
};

const GET_MANY = {
  query: `
      query($query: QueryInput) {
        offers(query: $query) {
          items {
            id
            requireApproval
            approved
            adminComment
            tenantId
            archived
            workspaceId
            name
            countDown
            status
            issuerCompanyId
            bankerEmails
            tombstoneImageId
            offerPresentationTitle
            offerPresentationUrl
            enableOfferPresentation
            headerImageId
            headerType
            headerVideoId 
            listingType
            typeOfOffer
            processType
            republicProcessType
            wefunderProcessType
            realexProcessType
            offerPassword
            visibility
            sector
            investmentInstrument
            primaryObjective
            averageDailyProduction
            netAcres
            rssFeeds
            county
            dynamicsProjectId
            enableInvestment
            paymentDetails {
              id
              subscriptionAgreementDocumentId
              brokerageAgreementDocumentId
              pricePerShareOrToken
              acceptedPaymentMethod
              acceptedBankTypes
              acceptedCryptoTypes
              ethWalletAddress
              btcWalletAddress
              xlmWalletAddress
              xrpWalletAddress
              usdcWalletAddress
              tusdWalletAddress
              paxWalletAddress
              bankName
              bankAddress
              beneficiaryName
              swiftCode
              accountNumber
              routingNumber
              memoLine
            }
            tenantHasDocusign
            area
            minimumInvestment
            targetRaise
            assetStatus
            valueRange
            sharesForSale
            highlights
            documents {
              id
              title
              url
              previewImageId
            }
            faqs {
              id
              question
              answer
            }
  
            sharedTenants {
              id
                tenantId
                published
                name
            }
            videoUrls
            videoFiles{
              id
              title
              url
              image
            }
            valuationCap
            discountRate
            coupon
            maturity
            previewUrl
            cashFee
            securitiesCommission
            closingDate
            republicUrl
            republicPreviewUrl
            wefunderUrl
            wefunderPreviewUrl
            realexPreviewUrl
            realexUrl
            isVanityUrl
            vanityUrl
            baseCurrency
            geographicMandate
            fundType
            domicileOption
            domicileOnshore
            domicileOffShore
            systematicDiscretionaryOption
            investmentCoActExemptionOption
            investmentAdvisorType
            leverage
            fundStrategyClassfication
            fundStrategyClassficationOne
            fundStrategyClassficationTwo
            fundStrategyClassficationThree
            aumfund
            fundCapacity
            trackRecordFund
            trackRecordFirm
            fundtype_other
            graphicmandate_other
            basecurrency_other
            domicileOnshoreFund_other
            domicileOffShore_other
            fundStructure
            openEndFund
            redemption
            lockupPeriod
            fundtype_other
            redemption_other
            investmentAdvisorType_state
            exemption
            foreign
            requestApprovalIssuers
            testWatersToggle
            altOfferTitle
            calendlyUrl
          }
          total
        }
      }
    `,
  parseResult: ({ data }) => ({
    data: data.offers.items,
    total: data.offers.total,
  }),
};

const offersConfig = {
  GET_LIST,

  GET_MANY,

  GET_ONE: {
    query: `
        query($id: ID!) {
          offer(_id: $id) {
            id
            requireApproval
            archived
            approved
            adminComment
            tenantId
            workspaceId
            name
            status
            issuerCompanyId
            bankerEmails
            tombstoneImageId
            offerPresentationTitle
            offerPresentationUrl
            enableOfferPresentation
            headerImageId
            headerType
            headerVideoId 
            listingType
            typeOfOffer
            processType
            republicProcessType
            realexProcessType
            realexUrl
            republicUrl
            wefunderProcessType
            wefunderUrl
            offerPassword
            visibility
            sector
            countDown
            dynamicsProjectId
            investmentInstrument
            primaryObjective
            averageDailyProduction
            netAcres
            county
            rssFeeds
            enableInvestment
            paymentDetails {
              id
              subscriptionAgreementDocumentId
              brokerageAgreementDocumentId
              pricePerShareOrToken
              acceptedPaymentMethod
              acceptedBankTypes
              acceptedCryptoTypes
              ethWalletAddress
              btcWalletAddress
              xlmWalletAddress
              xrpWalletAddress
              usdcWalletAddress
              tusdWalletAddress
              paxWalletAddress
              bankName
              bankAddress
              beneficiaryName
              swiftCode
              accountNumber
              routingNumber
              memoLine
            }
            sharedTenants {
              id
                tenantId
                published
                name
            }
            area
            minimumInvestment
            targetRaise
            assetStatus
            valueRange
            isVanityUrl
            vanityUrl
            sharesForSale
            highlights
            documents {
              id
              title
              url
              previewImageId
            }
            faqs {
              id
              question
              answer
            }
            videoFiles{
              id
              title
              url
              image
            }
            videoUrls
            previewUrl
            valuationCap
            discountRate
            coupon
            maturity
            cashFee
            securitiesCommission
            closingDate
            wefunderPreviewUrl
            realexPreviewUrl
            republicPreviewUrl
            baseCurrency
          geographicMandate
          fundType
          domicileOption
          domicileOnshore
          domicileOffShore
          systematicDiscretionaryOption
          investmentCoActExemptionOption
          investmentAdvisorType
          leverage
          fundStrategyClassfication
          fundStrategyClassficationOne
          fundStrategyClassficationTwo
          fundStrategyClassficationThree
          aumfund
          fundCapacity
          trackRecordFund
          trackRecordFirm
          fundtype_other
          graphicmandate_other
          basecurrency_other
          domicileOnshoreFund_other
          domicileOffShore_other
          fundStructure
          openEndFund
          redemption
          lockupPeriod
          fundtype_other
          redemption_other
          exemption
          investmentAdvisorType_state
          testWatersToggle
          foreign
          requestApprovalIssuer
          altOfferTitle
          calendlyUrl
          productDeveloper
          creditIssued
          product
          vintage
          region
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.offer }),
  },

  CREATE: {
    mutation: `
        mutation($data: OfferInput!) {
          createOffer(data: $data) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.createOffer }),
  },

  UPDATE: {
    mutation: `
        mutation($id: ID!, $data: OfferInput!, $previousData: OfferInput!) {
          updateOffer(_id: $id, data: $data, previousData: $previousData) {
            id
          }
        }
      `,
    excluded: [
      'previewUrl',
      'republicPreviewUrl',
      'wefunderPreviewUrl',
      'realexPreviewUrl',
      'requireApproval',
      'tenantHasDocusign',
    ],
    parseResult: ({ data }) => ({ data: data.updateOffer }),
  },

  DELETE: {
    mutation: `
        mutation($id: ID!) {
          removeOffer(_id: $id) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.removeOffer }),
  },
};

export default offersConfig;
