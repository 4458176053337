import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useNotify } from 'react-admin';

import Modal from '@mui/material/Modal';
import { makeStyles } from '@material-ui/core';
import { countries } from 'entoro-shared';
import { Form, Field } from 'react-final-form';
import { PhoneNumberInput, validatePhone } from 'utility/PhoneNumberInput';
import apolloClient from 'apollo/apolloClient';
import { issuerKycRequest } from 'apollo';
import { CONTACT_EMAIL_DEFAULT } from 'config';

import clsx from 'clsx';

import { Container, Button } from '@material-ui/core';

const KycPage = () => {
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const notify = useNotify();

  const onSubmit = async (values) => {
    try {
      const issuerUserId = JSON.parse(localStorage.getItem('issuer'))
        .issuerUserId;

      const response = await apolloClient.mutate({
        mutation: issuerKycRequest,
        variables: { input: { ...values, issuerUserId: issuerUserId } },
      });

      if (response?.data?.issuerKycRequest?.kycTransactionId) {
        setOpen(false);
        notify('Your KYC request has been received and it is been proccesed.', {
          type: 'success',
        });
      }
      setInputValue([]);
    } catch (error) {
      console.log('error', error);
      notify(error.message, { type: 'error' });
    }
  };

  const formatSSN = (value) => {
    if (!value) return value;

    const ssn = value.replace(/[^\d]/g, '');
    const ssnLength = ssn.length;

    if (ssnLength < 4) return ssn;

    if (ssnLength < 6) {
      return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
    }
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
  };

  const handleInput = (e) => {
    const formattedSSN = formatSSN(e.target.value);
    setInputValue(formattedSSN);
  };

  const style = {
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 700,
      height: '80%',
      overflow: 'scroll',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflowX: 'hidden',
    },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    btnStyle: {
      width: '19rem',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderColor: '#D7EAFB',
    },
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiFilledInput-input': {
        backgroundColor: 'white',
        border: '1px solid grey',
        borderRadius: '5px',
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className='app-inner-content-layout app-inner-content-layout-fixed'>
      <div
        className={clsx(
          'app-inner-content-layout--sidebar bg-white app-inner-content-layout--sidebar__lg order-1',
          { 'layout-sidebar-open': isSidebarMenuOpen }
        )}
      ></div>
      <div className='app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary'>
        <div className='card-header rounded-0 bg-white px-5 py-4 border-bottom'>
          <Container className='d-block text-center py-3 text-sm-left d-sm-flex align-items-center justify-content-between'>
            <div className='font-weight-bold font-size-xxl mb-3 mb-sm-0'>
              <div>Issuer KYC Submission</div>
            </div>
          </Container>
        </div>

        <div className='card-header rounded-0 bg-white px-5 py-4 border-bottom'>
          <Container className='d-block text-center py-3 text-sm-left d-sm-flex align-items-center justify-content-between'>
            <div className='font-weight-bold font-size-xxl mb-3 mb-sm-0 '>
              <p className='font-weight-normal font-size-lg py-3'>
                As a part of Entoro’s compliance onboarding, all officers must
                complete a Know Your Customer and Anti Money Laundering
                (KYC/AML) screening. This is conducted via a third-party
                service, Acuant. Please click the below button to begin this
                process.
              </p>
              <div className='d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  onClick={handleOpen}
                >
                  Perform KYC
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style.box}>
          <h1>Issuer KYC Verification</h1>
          <b>{`Current Status: Not Started`}</b>
          <br />
          <br />
          {
            'In order to continue the due diligence process, we require a KYC/AML screening as part of the process.'
          }
          <br />
          <br />
          <i>
            If you have any questions about verification or need assistance
            getting verified, please contact{' '}
            <a href={`mailto:${CONTACT_EMAIL_DEFAULT}`}>
              {CONTACT_EMAIL_DEFAULT}
            </a>
            .
          </i>
          <br />
          <br />
          <Form
            onSubmit={onSubmit}
            // initialValues={{ stooge: 'larry', employed: false }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div style={style.flexBox}>
                  <div>
                    <label>First Name</label>
                    <br />
                    <Field
                      style={style.btnStyle}
                      name='firstName'
                      component='input'
                      type='text'
                      required
                    />
                  </div>
                  <div>
                    <label>Last Name</label>
                    <br />
                    <Field
                      style={style.btnStyle}
                      name='lastName'
                      component='input'
                      type='text'
                      required
                      // placeholder='Last Name'
                    />
                  </div>
                </div>
                <br />
                <div style={style.flexBox}>
                  <div>
                    <label>Email</label>
                    <br />
                    <Field
                      style={style.btnStyle}
                      name='email'
                      component='input'
                      type='text'
                      required
                    />
                  </div>
                  <div>
                    <br />
                    <Field
                      style={style.btnStyle}
                      name='dob'
                      component='input'
                      type='date'
                      required
                    />
                  </div>
                </div>
                <br />
                <div style={style.flexBox}>
                  <PhoneNumberInput
                    className={classes.root}
                    source='officePhoneNumber'
                    addLabel
                    label='Office Phone Number'
                    validate={validatePhone}
                    required
                  />

                  {/* <PhoneNumberInput
                    className={classes.root}
                    source='mobilePhoneNumber'
                    addLabel
                    label='Office Phone Number'
                    validate={validatePhone}
                  /> */}
                </div>
                <br />
                <h3>Location</h3>
                <div style={style.flexBox}>
                  <div>
                    <Field
                      name='country'
                      component='select'
                      style={style.btnStyle}
                      required
                    >
                      {countries.options.map((country) => (
                        <option value={country.value}>{country.label}</option>
                      ))}
                    </Field>
                  </div>
                  <div>
                    <Field
                      style={style.btnStyle}
                      name='street'
                      component='input'
                      type='text'
                      placeholder='Street Address'
                      required
                    />
                  </div>
                </div>
                <br />
                <div style={style.flexBox}>
                  <div>
                    <Field
                      style={style.btnStyle}
                      name='zip'
                      component='input'
                      type='number'
                      placeholder='Zip code'
                      required
                    />
                  </div>
                  <div>
                    <Field
                      style={style.btnStyle}
                      name='city'
                      component='input'
                      type='text'
                      placeholder='City'
                      required
                    />
                  </div>
                </div>
                <br />
                <div style={style.flexBox}>
                  <div>
                    <Field
                      style={style.btnStyle}
                      name='state'
                      component='input'
                      type='text'
                      placeholder='State'
                      required
                    />
                  </div>
                  {values.country === 'US' ||
                  values.country === 'UM' ||
                  values.country === 'VI' ? (
                    <div>
                      <Field
                        name='nationalIdMasked'
                        component='input'
                        type='number'
                        required
                      >
                        {({ input }) => (
                          <input
                            style={style.btnStyle}
                            placeholder='SSN Number (XXX-XX-XXXX)'
                            onChange={(e) => handleInput(e)}
                            value={inputValue}
                          />
                        )}
                      </Field>
                    </div>
                  ) : null}
                </div>
                <br />
                <br />
                <div className='buttons'>
                  <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    disabled={submitting || pristine}
                  >
                    Submit
                  </Button>
                  <Button
                    variant='outlined'
                    type='button'
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            )}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default KycPage;
