import restConfig from '../rest/restConfig';
import apolloClient from '../apollo/apolloClient';
import { gql } from '@apollo/client';
import restParamsToApolloVariables from '../helpers/restParamsToApolloVariables';
import getErrorMessage from '../helpers/getErrorMessage';
const { query, mutate } = apolloClient;
const dataProvider = async (requestType, resourceName, requestParams) => {
  const resourceConfig = restConfig[resourceName];

  if (!resourceConfig) {
    throw new Error(`Resource "${resourceName}" has not been configured`);
  }

  const requestConfig = resourceConfig[requestType];

  if (!requestConfig) {
    throw new Error(
      `Unsupported request type "${requestType}" for "${resourceName}"`
    );
  }
  let result;
  const variables = restParamsToApolloVariables(
    requestType,
    requestConfig,
    requestParams
  );

  try {
    switch (requestType) {
      case 'GET_LIST':
      case 'GET_ONE':
      case 'GET_MANY':
      case 'GET_MANY_REFERENCE':
        result = await query({
          query: gql(requestConfig.query),
          variables,
          fetchPolicy: 'network-only', // Disable cache
        });
        break;
      case 'CREATE':
      case 'UPDATE':
      case 'DELETE':
        result = await mutate({
          mutation: gql(requestConfig.mutation),
          variables,
        });
        break;
      default:
        break;
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    throw new Error(errorMessage);
  }

  const parseResult = requestConfig.parseResult || ((r) => r);

  return parseResult(result);
};

export default dataProvider;
