import React, { useEffect, useState } from 'react';

import { Grid, Button } from '@material-ui/core';
import PageTitle from './PageTitle';
import Board from './Board';
import RecentInvestment from './RecentInvestment';
import Status from './Status';
import ProgressBar from 'components/ProgressBar';
import { dashboardRequest } from 'apollo';
import apolloClient from 'apollo/apolloClient';
import { SyncLoader, HashLoader } from 'react-spinners';

const Dashboard = (props) => {
  const [dashboardStat, setDashboardStat] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [issuerName, setIssuerName] = useState(null);

  const fetchDashboardStat = async () => {
    if (!dashboardStat) {
      try {
        setIsLoading(true);
        const { data } = await apolloClient.query({ query: dashboardRequest });
        setIsLoading(false);
        setDashboardStat(data);
        setIssuerName(
          JSON.parse(localStorage.getItem('issuer'))?.issuerCompany?.name
        );
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  //run multiple queries here
  useEffect(() => {
    fetchDashboardStat();
  }, [dashboardStat, isLoading]);

  return (
    <>
      {!dashboardStat ? (
        <div>
          <SyncLoader loading={isLoading} color='#3f51b5' size={20} />
        </div>
      ) : (
        <Grid container spacing={6}>
          <Grid item xl={12} l={12} md={12}>
            <PageTitle
              titleHeading={issuerName && issuerName + ' ' + 'Dashboard'}
              titleDescription='Track your progress and monitor performance in real time.'
            />

            {/* <div className='mb-5'>
              <ProgressBar />
            </div>

            <Board /> */}
            <div>
              <Status
                investments={dashboardStat?.investments?.items}
                totalInvestment={dashboardStat?.investments?.items?.length}
              />
            </div>
            <div className='mt-2'>
              <RecentInvestment investments={dashboardStat?.investments} />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Dashboard;
