import React from 'react';
import { RouteWithoutLayout } from 'react-admin';
import ResetPassowrd from '../components/AuthPage/PageReset';
import ForgotPassowrd from '../components/AuthPage/PageRecover';
import PageLogin from '../components/AuthPage/PageLogin';
import DocumentDashboard from '../components/Dashboard/DocumentDashboard';
import LeftSidebar from '../components/LeftSidebar/index';
import KycView from 'components/Kyc';
export default [
  <RouteWithoutLayout exact path='/login' component={PageLogin} />,
  <RouteWithoutLayout
    exact
    component={ResetPassowrd}
    path='/reset-password/:salt'
  />,
  <RouteWithoutLayout
    exact
    component={ForgotPassowrd}
    path='/forgot-password'
  />,
  <RouteWithoutLayout
    exact
    component={ForgotPassowrd}
    path='/offer-preview/:id'
  />,
  <RouteWithoutLayout
    exact
    component={DocumentDashboard}
    path='/document-upload'
  />,
  <RouteWithoutLayout exact component={KycView} path='/kyc' />,
];
