const GET_LIST = {
  query: `
      query($query: QueryInput) {
        tenantsShared(query: $query) {
          items {
            id
            name
            domainPrefix
            customDomain
            marketingSiteUrl
            footerBrokerContent
            headerHtml
            bodyHtml
            availableListingTypes
            availableSectors
            primaryContactId
            docusign {
              accountId
            }
          }
          total
        }
      }
    `,
  parseResult: ({ data }) => ({
    data: data.tenantsShared.items,
    total: data.tenantsShared.total,
  }),
};

const tenantsConfig = {
  GET_LIST,

  GET_MANY: GET_LIST,
  GET_ONE: {
    query: `
        query {
          currentTenant {
            id
            name
            domainPrefix
            marketingSiteUrl
            footerBrokerContent
            headerHtml
            bodyHtml
            marketingEmails
            logoId
            faviconId
            logoUrl
            faviconUrl
            primaryColor
            primaryContactId
            availableListingTypes
            availableSectors
            anonymousHeaderText
            docusign {
              apiUrl
              accountId
              accountEmail
              accountPassword
              integrationKey
              secretKey
            }
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.currentTenant }),
  },

  UPDATE: {
    mutation: `
        mutation($id: ID!, $data: TenantInput!) {
          updateTenant(_id: $id, data: $data) {
            id
          }
        }
      `,
    excluded: ['logoUrl', 'faviconUrl'],
    parseResult: ({ data }) => ({ data: data.updateTenant }),
  },
};

export default tenantsConfig;
