import React, { useState } from 'react';

import { Grid, Container, Button, TextField } from '@material-ui/core';
import { useNotify, Notification } from 'react-admin';
import apolloClient from 'apollo/apolloClient';
import { resetIssuerAdmin } from 'apollo';
import { useHistory, useParams } from 'react-router-dom';

import logo from '../logo.png';
import illustration1 from 'assets/images/illustrations/pack1/security.svg';

export default function RecoverPage({ isLoading }) {
  const notify = useNotify();
  const { salt } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  // const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !cpassword) {
      notify('password must not be empty');
      return;
    }

    if (password !== cpassword) {
      notify('password and confirm password must be the same');
      return;
    }

    // setLoading(true);
    try {
      await apolloClient.mutate({
        mutation: resetIssuerAdmin,
        variables: {
          password,
          salt,
        },
      });
      // setLoading(false);
      history.push('/login');
    } catch (error) {
      // setLoading(false);
      console.log(error);
      //const errorMessage = getErrorMessage(error);
      //  console.log(error);
      notify(error.graphQLErrors[0].message, 'warning');

      //throw new Error(error);
    }
  };

  return (
    <>
      <div className='app-wrapper bg-white min-vh-100'>
        <div className='app-main min-vh-100'>
          <div className='app-content p-0'>
            <div className='app-content--inner d-flex align-items-center'>
              <div className='flex-grow-1 w-100 d-flex align-items-center'>
                <div className='bg-composed-wrapper--content py-5'>
                  <Notification />
                  <Container>
                    <Grid container spacing={6}>
                      <Grid item lg={6} className='d-flex align-items-center'>
                        <div className='divider-v d-none d-lg-block divider-v-md' />
                        <div className='w-100 pr-0 pr-lg-5'>
                          <div className='text-center my-5'>
                            <h1 className='display-4 mb-1 font-weight-bold'>
                              Reset Password
                            </h1>
                            <p className='font-size-lg mb-0 text-black-50'>
                              Reset your password?
                            </p>
                          </div>

                          <form onSubmit={handleSubmit}>
                            <div>
                              <label
                                tmlFor='password'
                                className='font-weight-bold mb-2'
                              >
                                New Password
                              </label>
                              <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder='Enter new your password'
                                type='password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                name='password'
                              />
                            </div>
                            <div className='mb-4'>
                              <label
                                htmlFor='cpassword'
                                className='font-weight-bold mb-2'
                              >
                                Confirm new password
                              </label>
                              <TextField
                                variant='outlined'
                                size='small'
                                fullWidth
                                placeholder='Enter new your password'
                                type='password'
                                value={cpassword}
                                onChange={(e) => setCpassword(e.target.value)}
                                name='cpassword'
                              />
                            </div>
                            <div className='text-center mb-5'>
                              <Button
                                fullWidth
                                type='submit'
                                className='text-uppercase font-weight-bold font-size-sm mt-4 btn-primary'
                              >
                                Update Password
                              </Button>
                            </div>
                            <div className='text-center mb-5'>
                              <img
                                alt='...'
                                className='w-50 mx-auto d-block img-fluid'
                                src={logo}
                              />
                            </div>
                          </form>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        className='d-none d-lg-flex align-items-center'
                      >
                        <img
                          alt='...'
                          className='w-100 mx-auto d-block img-fluid'
                          src={illustration1}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
