// Remove "__typename" fields from GraphQL objects
const removeTypename = (data) => {
    if (Array.isArray(data)) {
        return data.map(removeTypename);
    } else if (data && typeof data === 'object') {
        const cleanData = {};

        Object.keys(data).forEach((key) => {
            if (key !== '__typename') {
                cleanData[key] = removeTypename(data[key]);
            }
        });

        return cleanData;
    }

    return data;
};

export default removeTypename;
