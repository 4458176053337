import React, { useState } from 'react';

import { Grid, Dialog } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Hiw from 'components/Hiw';
import FAQ from 'components/Faq';
import PageContactus from 'components/Contact';
import hero3 from 'assets/images/hero-bg/hero-3.jpg';
const HeaderMenu = () => {
  const [modal1, seModal1] = useState(false);
  const [modal2, seModal2] = useState(false);
  const [modal3, seModal3] = useState(false);

  const toggle1 = (e) => {
    e.preventDefault();
    seModal1(!modal1);
  };
  const toggle2 = (e) => {
    e.preventDefault();
    seModal2(!modal2);
  };
  const toggle3 = (e) => {
    e.preventDefault();
    seModal3(!modal3);
  };

  return (
    <>
    </>
    // <div className='app-header-menu'>
    //   <div className='header-nav-wrapper navbar-light'>
    //     <div className='header-nav-menu d-none d-lg-block'>
    //       <ul className='d-flex nav nav-neutral-first justify-content-center'>
    //         <li>
    //           <NavLink
    //             to='/faqs'
    //             onClick={(e) => toggle2(e)}
    //             className='font-weight-bold rounded-sm px-3'
    //           >
    //             FAQs
    //           </NavLink>
    //           <Dialog
    //             scroll='body'
    //             maxWidth='lg'
    //             open={modal2}
    //             onClose={toggle2}
    //             classes={{ paper: 'modal-content border-0 rounded bg-white' }}
    //           >
    //             <Grid container spacing={0}>
    //               <Grid item xl={6} className='p-3 p-xl-0'>
    //                 <div className='hero-wrapper bg-composed-wrapper bg-grow-early h-100 rounded br-xl-right-0'>
    //                   <div className='flex-grow-1 w-100 d-flex align-items-center'>
    //                     <div
    //                       className='bg-composed-wrapper--image rounded br-xl-right-0'
    //                       style={{ backgroundImage: 'url(' + hero3 + ')' }}
    //                     />
    //                     <div className='bg-composed-wrapper--bg bg-second opacity-5 rounded br-xl-right-0' />
    //                     <div className='bg-composed-wrapper--content text-center p-5'>
    //                       <div className='text-white'>
    //                         <h1 className='display-3 my-3 font-weight-bold'>
    //                           Frequently asked questions
    //                         </h1>
    //                         <p className='font-size-lg mb-0 text-white-50'>
    //                           De apocalypsi gorger omero undead survivor
    //                           dictum mauris. Hi mindless mortuis soulless
    //                           creaturas, imo evil stalking monstra adventus
    //                           resi dentevil vultus comedat cerebella viventium
    //                         </p>
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className='hero-footer pb-4'></div>
    //                 </div>
    //               </Grid>
    //               <Grid item xl={6}>
    //                 <div className='bg-white rounded br-xl-left-0'>
    //                   <div className='text-center p-4'>
    //                     <FAQ />
    //                   </div>
    //                 </div>
    //               </Grid>
    //             </Grid>
    //           </Dialog>
    //         </li>
    //         <li>
    //           <NavLink
    //             to='/contactus'
    //             onClick={(e) => toggle3(e)}
    //             className='font-weight-bold rounded-sm px-3'
    //           >
    //             HELP AND SUPPORT
    //           </NavLink>
    //           <Dialog
    //             scroll='body'
    //             maxWidth='lg'
    //             open={modal3}
    //             onClose={toggle3}
    //             classes={{ paper: 'modal-content' }}
    //           >
    //             {/* <DialogContent className='p-0'> */}
    //             <div className='hero-wrapper bg-composed-wrapper  m-4 bg-grow-early h-100 rounded br-xl-right-0'>
    //               <div className='flex-grow-1 w-100 d-flex align-items-center'>
    //                 <div
    //                   className='bg-composed-wrapper--image  rounded br-xl-right-0'
    //                   style={{ backgroundImage: 'url(' + hero3 + ')' }}
    //                 />
    //                 <div className='bg-composed-wrapper--bg bg-second opacity-5 rounded br-xl-right-0' />
    //                 <div className='bg-composed-wrapper--content text-center p-5'>
    //                   <div className='text-white'>
    //                     <h1 className='display-3 my-3 font-weight-bold'>
    //                       Contact Us
    //                     </h1>
    //                     <p className='font-size-lg mb-0 text-white-50'>
    //                       adventus resi dentevil vultus comedat cerebella
    //                       viventium
    //                     </p>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className='hero-footer pb-4'></div>
    //             </div>
    //             <PageContactus />
    //           </Dialog>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </div>
  );
};

export default HeaderMenu;
