import {
  CheckboxGroupInput as AorCheckboxGroupInput,
  SelectInput as AorSelectInput,
} from 'react-admin';
import AorSelectArrayInput from 'utility/ChipInput';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
// Create wrapper with expected value/label fields in options
const withSelectInputWrapper = (component) => {
  const WrappedSelectInput = ({
    source,
    label,
    options,
    styleOptions,
    defaultValue,
    ...otherProps
  }) => (
    <Field
      name={source}
      label={label || startCase(source)}
      component={component}
      optionValue='value'
      optionText='label'
      choices={options}
      options={styleOptions}
      defaultValue={defaultValue}
      {...otherProps}
    />
  );

  WrappedSelectInput.propTypes = {
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    styleOptions: PropTypes.object,
  };

  WrappedSelectInput.defaultProps = {
    label: undefined,
    options: undefined,
    styleOptions: undefined,
  };

  return WrappedSelectInput;
};

export const SelectInput = withSelectInputWrapper(AorSelectInput);
export const SelectArrayInput = withSelectInputWrapper(AorSelectArrayInput);
export const CheckboxGroupInput = withSelectInputWrapper(AorCheckboxGroupInput);
