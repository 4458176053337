import React from 'react';
import { gql } from '@apollo/client';
import apolloClient from '../../apollo/apolloClient';

import { Button, TextField } from '@material-ui/core';
const sendContact = gql`
  mutation($message: String!) {
    sendIssuerContact(message: $message)
  }
`;
class Contact extends React.Component {
  state = {
    message: '',
    loading: false,
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      const data = await apolloClient.mutate({
        mutation: sendContact,
        variables: { message: this.state.message },
      });
      this.setState({ loading: false, message: '' });
      alert('Sent Successfully');
    } catch (error) {
      this.setState({ loading: false });
      console.log('error', error);
    }
  };
  render() {
    return (
      <>
        <div>
          <div className='bg-secondary border-0'>
            <div className='card-body  '>
              <form onSubmit={this.handleSubmit}>
                <div className='mb-3'>
                  <TextField
                    value={this.state.message}
                    onChange={(e) => this.setState({ message: e.target.value })}
                    fullWidth
                    className='m-2'
                    id='standard-multiline-static'
                    label='Enter Message'
                    multiline
                    rows='4'
                    variant='outlined'
                    placeholder='Enter Message'
                  />
                </div>

                <div className='text-center'>
                  <Button
                    type='submit'
                    disabled={this.state.loading}
                    variant='contained'
                    className='font-weight-bold btn-second px-4 my-2'
                  >
                    {this.state.loading ? 'Submitting...' : 'Send Message'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
