import { omit, pick } from 'lodash';
import removeTypename from './removeTypename';
import restParamsToQueryInput from './restParamsToQueryInput';

// Clean and transform REST params into variables that can be used by Apollo
const restParamsToApolloVariables = (
  requestType,
  requestConfig,
  requestParams
) => {
  let variables = { ...requestParams };

  // Exclude OR include fields to be submitted to the query/mutation (whichever is more convenient)
  const { excluded = [], included = [] } = requestConfig;

  if (excluded.length) {
    // Exclude fields such as relational data objects (only relational IDs should be submitted)
    variables = {
      ...variables,
      data: omit(variables.data, excluded),
      previousData: omit(variables.previousData, excluded),
    };
  } else if (included.length) {
    // Some CRUD forms have many readonly fields. We only want to submit the editable fields
    variables = {
      ...variables,
      data: pick(variables.data, included),
      previousData: pick(variables.previousData, included),
    };
  }
  switch (requestType) {
    case 'GET_LIST':
    case 'GET_MANY_REFERENCE': {
      variables = restParamsToQueryInput(variables);
      break;
    }
    case 'GET_MANY':
      // Array of IDs is sent. Eventually replace ReferenceArrayInput with custom component
      break;
    case 'UPDATE':
      variables = removeTypename(variables);
      variables = {
        ...variables,
        // admin-on-rest uses the "id" field as its primary key. We use Mongo "_id"
        data: omit(variables.data, ['id']),
        previousData: omit(variables.previousData, ['id']),
      };
      break;
    default:
      break;
  }

  return variables;
};

export default restParamsToApolloVariables;
