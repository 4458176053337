import { FunctionField as AorFunctionField } from 'react-admin';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const FunctionField = ({ source, render, ...otherProps }) => (
    <AorFunctionField
        render={(record) => {
            if (!record) {
                return '';
            }

            const value = get(record, source);

            return value !== undefined ? render(value, record) : '';
        }}
        source={source}
        {...otherProps}
    />
);

FunctionField.propTypes = {
    source: PropTypes.string.isRequired,
    render: PropTypes.func.isRequired,
    addLabel: PropTypes.bool,
};

FunctionField.defaultProps = {
    addLabel: true,
};

export default FunctionField;
