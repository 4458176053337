import { gql } from '@apollo/client';

export default gql`
  query currentTenant {
    currentTenant {
      _id
      name
      domainPrefix
      customDomain
      logoUrl
      faviconUrl
      marketingSiteUrl
      footerBrokerContent
      headerHtml
      bodyHtml
      availableListingTypes
      availableSectors
      primaryContactEmail
      anonymousHeaderText
      theme {
        enabled
        primaryColor
        secondaryColor
      }
    }
  }
`;
