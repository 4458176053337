import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Button, List, ListItem } from '@material-ui/core';

import avatar4 from 'assets/images/avatars/avatar4.jpg';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import { useLogout } from 'react-admin';

const HeaderUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const logout = useLogout();
  const handleLogout = () => logout();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className='user-box position-relative mr-2'>
        <Button
          onClick={handleLogout}
          className='btn-link p-0 text-left d-flex align-items-center'
        >
          <div className='d-block p-0 avatar-icon-wrapper'>
            <div className=''>
              <ExitToAppTwoToneIcon />
            </div>
          </div>
          <div className='d-none d-xl-block  '>
            <div className='font-weight-bold'>lOGOUT</div>
          </div>
        </Button>
      </div>
    </>
  );
};

export default HeaderUserbox;
