import React from 'react';
import LayoutWithoutTheme from '../../Layout/BaseLayout';
import KycPage from './KycPage';

const KycView = () => {
  return (
    <LayoutWithoutTheme>
      <KycPage />
    </LayoutWithoutTheme>
  );
};

export default KycView;
