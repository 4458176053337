import apolloClient from '../apollo/apolloClient';
// import { getErrorMessage } from 'entoro-shared';
import { loginIssuerAdmin } from 'apollo';
import jwtDecode from 'jwt-decode';

const authProvider = {
  // authentication
  login: async ({ email, password }) => {
    // Check for auth in localstorage to prevent login page loop
    localStorage.removeItem('authToken');
    localStorage.removeItem('userType');

    const { data } = await apolloClient.mutate({
      mutation: loginIssuerAdmin,
      variables: {
        email,
        password,
      },
    });

    const { authToken } = data.loginIssuerAdmin;
    const { userType } = jwtDecode(authToken);
    localStorage.setItem('authToken', authToken);
    localStorage.setItem('issuer', JSON.stringify(jwtDecode(authToken)));
    localStorage.setItem('userType', userType);

    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userType');
      localStorage.removeItem('issuer');
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('authToken')
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userType');
    localStorage.removeItem('issuer');
    return Promise.resolve();
  },
  getIdentity: () => {
    const issuer = JSON.parse(localStorage.getItem('issuer'));

    return Promise.resolve(issuer);
  },
  // authorization
  getPermissions: (params) => {
    return Promise.resolve(localStorage.getItem('userType'));
  },
};
export default authProvider;
