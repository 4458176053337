import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import BooleanInput from './BooleanInput';
// Create wrapper with expected value/label fields in options
const withSelectInputWrapper = (component) => {
  const WrappedSelectInput = ({ source, label, ...otherProps }) => (
    <Field
      name={source}
      label={label || startCase(source)}
      component={component}
      {...otherProps}
    />
  );

  WrappedSelectInput.propTypes = {
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
  };

  WrappedSelectInput.defaultProps = {
    label: undefined,
  };

  return WrappedSelectInput;
};

export default withSelectInputWrapper(BooleanInput);
