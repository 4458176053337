import React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import { SelectInput } from './SelectInput';

const withTenantWrapper = (Component) => {
  const WrappedInput = ({ options, ...otherProps }, context) => {
    const state = useSelector((state) => state.TenantReducer);

    const filteredSectorOptions = state.tenant
      ? options.filter(({ value }) =>
          state.tenant.availableSectors.includes(value)
        )
      : options;

    return <Component {...otherProps} options={filteredSectorOptions} />;
  };

  WrappedInput.propTypes = {
    availableSectors: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  };

  WrappedInput.defaultProps = {
    label: undefined,
    options: undefined,
    availableSectors: undefined,
  };

  WrappedInput.contextTypes = {
    tenant: PropTypes.object,
  };

  return WrappedInput;
};

// const mapStateToProps = (state) => {
//     const selector = formValueSelector('record-form');
//     const tenantId = selector(state, 'tenantId');
//     const availableSectors = get(
//         state,
//         `admin.resources.tenants.data[${tenantId}].availableSectors`
//     );

//     return {
//         availableSectors,
//     };
// };

const SectorSelectInput = withTenantWrapper(SelectInput);

export default SectorSelectInput;
