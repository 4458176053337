import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-image-crop/dist/ReactCrop.css';
import { showNotification as showNotificationAction } from 'react-admin';
import { CLOUDINARY_UPLOAD_PRESET } from '../config';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
const styles = {
  uploadButtonStyle: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: '0',
    zIndex: 10,
  },
  isUploadingStyle: {
    position: 'absolute',
    zIndex: 1,
    opacity: 0.6,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgb(80,80,80)',
  },
  imageStyle: {
    border: '1px solid #EEE',
    marginBottom: '10px',
    maxHeight: '300px',
  },
  button: {
    width: 'auto',
    margin: '10px 10px 10px 0px',
  },
  encodedFile: {
    position: 'relative',
    display: 'inline-flex',
  },
  noticeStyle: {
    fontSize: '13px',
    color: '#F44336',
    margin: '0',
  },
};

const encodeFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

class ImageInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      encodedFile: null,
      crop: {},
      isUploading: false,
    };
  }

  onChange = async (event) => {
    const [firstFile] = event.target.files;

    if (!firstFile) {
      return;
    }

    const encodedFile = await encodeFile(firstFile);

    this.setState({ encodedFile });
  };

  onImageLoaded = (image) => {
    const { aspect } = this.props;
    const { width, height } = image;

    let crop;

    if (aspect) {
      crop = makeAspectCrop(
        {
          x: 0,
          y: 0,
          aspect,
          width: 50,
        },
        width / height
      );
    } else {
      crop = {
        x: 0,
        y: 0,
        width: 50,
        height: 50,
      };
    }

    this.setState({ crop });
  };

  uploadImage = async (file) => {
    const {
      showNotification,
      input: { onChange },
    } = this.props;

    try {
      this.setState({ isUploading: true });

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/`,
        {
          file,
          // https://cloudinary.com/documentation/image_upload_api_reference#upload
          // Upload preset required for unsigned uploading
          upload_preset: CLOUDINARY_UPLOAD_PRESET,
        }
      );

      this.setState(
        { isUploading: false, encodedFile: null, crop: null },
        () => {
          onChange(response.data.public_id);
          showNotification('Image uploaded');
        }
      );
    } catch (error) {
      this.setState({ isUploading: false }, () => {
        showNotification(error.message);
      });
    }
  };

  updateCrop = (crop) => {
    this.setState({ crop });
  };

  cropImage = () => {
    const { encodedFile, crop } = this.state;

    const loadedImg = new Image();
    loadedImg.src = encodedFile;
    const imageWidth = loadedImg.naturalWidth;
    const imageHeight = loadedImg.naturalHeight;
    const cropX = (crop.x / 100) * imageWidth;
    const cropY = (crop.y / 100) * imageHeight;
    const cropWidth = (crop.width / 100) * imageWidth;
    const cropHeight = (crop.height / 100) * imageHeight;
    const canvas = document.createElement('canvas');
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      loadedImg,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    this.uploadImage(canvas.toDataURL('image/png'));
  };

  renderImage() {
    const {
      label,
      input: { value },
      meta: { touched, error },
      disabledField,
    } = this.props;
    const { isUploading, encodedFile } = this.state;

    if (encodedFile) {
      return (
        <div>
          <div style={styles.encodedFile}>
            {isUploading && (
              <div style={styles.isUploadingStyle}>
                <CircularProgress color='white' />
              </div>
            )}
            <ReactCrop
              disabled={isUploading}
              imageStyle={{ maxHeight: '300px' }}
              src={encodedFile}
              onChange={this.updateCrop}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
            />
          </div>
          {touched && error ? <p style={styles.noticeStyle}>Required</p> : null}
        </div>
      );
    }

    if (!value) {
      return touched && error ? (
        <p style={styles.noticeStyle}>Required</p>
      ) : null;
    }

    return (
      <div>
        <img
          alt={label}
          src={`https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/q_100/${value}`}
          style={styles.imageStyle}
          className='img-checkered-bg'
        />
        {!disabledField && (
          <p style={styles.noticeStyle}>
            * Be sure to save this form if adding new image
          </p>
        )}
      </div>
    );
  }

  render() {
    const { recommendedAspect, disabledField } = this.props;
    const { encodedFile, isUploading } = this.state;

    return (
      <MuiThemeProvider>
        <div>
          {!disabledField && (
            <div>
              {encodedFile ? (
                <div style={{ display: 'block' }}>
                  <RaisedButton
                    disabled={isUploading}
                    style={styles.button}
                    label='Cancel'
                    onClick={() =>
                      this.setState({ crop: null, encodedFile: null })
                    }
                  />
                  <RaisedButton
                    disabled={isUploading}
                    style={styles.button}
                    label='Save Crop'
                    onClick={this.cropImage}
                  />
                </div>
              ) : (
                <RaisedButton style={styles.button} label='Select Image'>
                  <input
                    type='file'
                    accept='image/*'
                    style={styles.uploadButtonStyle}
                    onChange={this.onChange}
                  />
                </RaisedButton>
              )}
              {recommendedAspect && (
                <p style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '11px' }}>
                  Tip: <strong>{recommendedAspect}</strong>
                </p>
              )}
            </div>
          )}
          {this.renderImage()}
        </div>
      </MuiThemeProvider>
    );
  }
}

let FieldWrapper = ({
  label,
  source,
  showNotification,
  aspect,
  recommendedAspect,
  validate,
  disabledField,
}) => (
  <Field
    label={label}
    name={source}
    component={ImageInput}
    showNotification={showNotification}
    aspect={aspect}
    recommendedAspect={recommendedAspect}
    validate={validate}
    disabledField={disabledField}
  />
);

ImageInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
  aspect: PropTypes.number,
  recommendedAspect: PropTypes.string,
};

ImageInput.defaultProps = {
  aspect: undefined,
  recommendedAspect: undefined,
};

FieldWrapper.propTypes = {
  label: PropTypes.node.isRequired,
  source: PropTypes.string.isRequired,
  showNotification: PropTypes.func.isRequired,
  aspect: PropTypes.number,
  recommendedAspect: PropTypes.string,
  validate: PropTypes.func,
};

FieldWrapper.defaultProps = {
  aspect: undefined,
  recommendedAspect: undefined,
  validate: null,
  disabledField: false,
};

export default connect(null, {
  showNotification: showNotificationAction,
})(FieldWrapper);
