import React from 'react';
import {
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  CreateButton,
  ExportButton,
  TopToolbar,
  ShowButton,
} from 'react-admin';
import EditButton from 'utility/EditButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ManagementTeamCreateEditForm from './Form/ManagementTeamForm';
const container = {
  maxWidth: '18em',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '15px',
};

const ListActions = () => {
  let isBanker =
    JSON.parse(localStorage.getItem('issuer'))?.issuerType ===
    'ISSUER_MODERATOR_ADMIN';
  return (
    <TopToolbar>
      {!isBanker && <CreateButton />}
      <ExportButton />
    </TopToolbar>
  );
};
const FullNameField = ({ record = {} }) => (
  <span>
    {record.firstName} {record.lastName}
  </span>
);

export const ManagementTeamCreate = (props) => (
  <Create {...props} title='Create Management Team Member'>
    <ManagementTeamCreateEditForm
      redirect='list'
      allowEmpty
      disabledField={
        JSON.parse(localStorage.getItem('issuer'))?.issuerType ===
        'ISSUER_MODERATOR_ADMIN'
      }
      //permissions={permissions}
    />
  </Create>
);

export const ManagementTeamEdit = (props) => (
  <Edit {...props} title='Edit Management Team Member'>
    <ManagementTeamCreateEditForm
      redirect='list'
      allowEmpty
      disabledField={
        JSON.parse(localStorage.getItem('issuer'))?.issuerType ===
        'ISSUER_MODERATOR_ADMIN'
      }
      //permissions={permissions}
    />
  </Edit>
);

export const ManagementTeamList = (props) => {
  let isBanker =
    JSON.parse(localStorage.getItem('issuer'))?.issuerType ===
    'ISSUER_MODERATOR_ADMIN';
  return (
    <List {...props} actions={<ListActions />} title='Manage Team'>
      <Datagrid className='card-box mb-spacing-6-x2'>
        <TextField
          style={container}
          source='issuerCompany.name'
          label='Company'
          sortable={false}
        />
        <FullNameField style={container} source='firstName' label='Full Name' />

        {/* <EmailField style={container} source="email" /> */}
        <TextField style={container} source='position' />
        {isBanker ? (
          <ShowButton label='Show' {...props} basePath='/managementTeam' />
        ) : (
          <EditButton label='Action' basePath='/managementTeam' />
        )}
      </Datagrid>
    </List>
  );
};
