import { isEmpty, isArray, isString } from 'lodash';

// TODO: Use https://www.npmjs.com/package/validator

export const required = (value) => {
    if (isString(value) && !value.trim()) return 'Required';
    if (isArray(value) && isEmpty(value)) return 'Required';
    if (!value && typeof value !== 'number') return 'Required';
    return undefined;
};

export const email = value =>
(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined);

export const minLength = min => value =>
    (value && value.length < min ? `Must be ${min} characters or more` : undefined);

export const minLength8 = minLength(8);

export const phoneNumber = value => (
    value && !/^(0|[1-9][0-9]{9})$/i.test(value.replace(/-/g, ''))
        ? 'Invalid phone number, must be 10 digits'
        : undefined
);

export const url = value => (
    value && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)
        ? 'Invalid URL, must start with http:// or https://'
        : undefined
);

export const dob = value => (
    value && !/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)
        ? 'Invalid Date of Birth, must be YYYY-MM-DD format'
        : undefined
);
