import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const Accordion = withStyles({
  root: {
    boxShadow: 'none',

    width: '100%',
    margin: '5px 0',
    '& h4': {
      letterSpacing: '1.5px',
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      width: '100%',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 50,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '&$expanded': {
      minHeight: 50,
      background: 'rgb(42, 88, 167) 0%',
      borderBottom: 'none',

      '& h4': {
        color: '#fff',
      },
      '& span': {
        '& svg': {
          fill: '#fff',
        },
      },
    },
  },
  content: {
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

class ExpandSection extends React.Component {
  //const [expanded, setExpanded] = React.useState('panel1');
  constructor(props) {
    super(props);
    this.state = {
      expanded: 'panel1',
    };
  }
  handleChange = (panel) => (event, newExpanded) => {
    if (this.props.children) {
      this.setState({ expanded: newExpanded ? panel : false });
    } else {
      this.setState({ expanded: false });
    }
  };
  render() {
    const { children } = this.props;
    return (
      <Accordion
        square
        expanded={this.state.expanded === `${this.props.name}`}
        onChange={this.handleChange(`${this.props.name}`)}
      >
        <AccordionSummary
          aria-controls={`${this.props.name + 'd-content'}`}
          id={`${this.props.name + 'd-header'}`}
          expandIcon={children && <ExpandMoreIcon />}
        >
          <h4 style={{ fontSize: '17px' }}> {this.props.name}</h4>
        </AccordionSummary>

        {children}
      </Accordion>
    );
  }
}

export default ExpandSection;
