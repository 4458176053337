import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card } from '@material-ui/core';

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import CountUp from 'react-countup';

export default function Status({ totalInvestment, investments }) {
  const [funded, setFunded] = useState(0);

  const handleFFFunded = () => {
    let total = 0;
    if (investments.length > 0) {
      for (let index = 0; index < investments.length; index++) {
        const investment = investments[index];
        if (investments[index].investmentStatus === 'INVESTMENT_COMPLETE') {
          total = total + investments[index].investmentAmount;
        }
      }
      setFunded(
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(total)
      );
    }
  };

  useEffect(() => {
    handleFFFunded();
  }, []);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item md={6} xl={6} l={6}>
          <Card className='card-box bg-royal p-3  '>
            <div className='d-flex align-items-center'>
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  textColor: 'var(--white)',
                  pathColor: 'rgba(255,255,255,.95)',
                  trailColor: 'rgba(255,255,255,.1)',
                })}
                value={100}
                strokeWidth={6}
                className='circular-progress-success'
              >
                <div className='d-40 rounded-circle bg-first btn-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'money-check-alt']}
                    className='font-size-lg text-white'
                  />
                </div>
              </CircularProgressbarWithChildren>
              <div className='pl-3'>
                <div className='text-white font-weight-bold'>Funded</div>
                <div className='display-4 font-weight-bold pt-2 text-white'>
                  {funded}
                  {/* <CountUp
                    start={0}
                    end={funded}
                    duration={6}
                    delay={2}
                    separator=''
                    decimals={0}
                    decimal=','
                  /> */}
                </div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item md={6} xl={6} l={6}>
          <Card className='card-box bg-royal p-3 '>
            <div className='d-flex align-items-center'>
              <CircularProgressbarWithChildren
                styles={buildStyles({
                  textColor: 'var(--white)',
                  pathColor: 'rgba(255,255,255,.95)',
                  trailColor: 'rgba(255,255,255,.1)',
                })}
                value={100}
                strokeWidth={6}
                className='circular-progress-success'
              >
                <div className='d-40 rounded-circle bg-danger  btn-icon'>
                  <FontAwesomeIcon
                    icon={['fas', 'wallet']}
                    className='font-size-lg text-white'
                  />
                </div>
              </CircularProgressbarWithChildren>
              <div className='pl-3'>
                <div className='text-white font-weight-bold'>
                  {' '}
                  Total Investment
                </div>
                <div className='display-4 font-weight-bold pt-2 text-white'>
                  <CountUp
                    start={0}
                    end={totalInvestment}
                    duration={6}
                    delay={2}
                    separator=''
                    decimals={0}
                    decimal=','
                  />
                </div>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
