import React from 'react';
import OfferCreateEditForm from './Form/OfferForm';
import { Edit } from 'react-admin';
export const OfferEdit = (props) => (
  <Edit {...props} hasShow={false}>
    <OfferCreateEditForm
      //  permissions={permissions}
      redirect='list'
      name='Edit Offer'
      allowEmpty
    />
  </Edit>
);
