import React, { useEffect, useState } from 'react';

import clsx from 'clsx';

import classes from './button.module.scss';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SubjectIcon from '@mui/icons-material/Subject';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  LinearProgress,
  Card,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useDropzone } from '../../../hooks/useDocUpload';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import PublishTwoToneIcon from '@material-ui/icons/PublishTwoTone';
import CheckIcon from '@material-ui/icons/Check';
import LayoutWithoutTheme from '../../../Layout/BaseLayout';
import FolderTwoToneIcon from '@material-ui/icons/FolderTwoTone';
import axios from 'axios';
import { useNotify } from 'react-admin';
import { fontSize } from '@mui/system';
import { issuerCompany } from 'apollo';
import apolloClient from 'apollo/apolloClient';
import { useDispatch } from 'react-redux';

const FolderDocumentDashboard = () => {
  const [uploading, setUploading] = useState(false);
  const [isSidebarMenuOpen, setIsSidebarMenuOpen] = useState(false);
  const [folderPath, setFolderPath] = useState('');
  const [marketingView, setMarkeingView] = useState(false);

  const [activeTab, setActiveTab] = useState('0');
  const [selectedFolder, setSelectedFolder] = useState(false);

  const [folders, setFolder] = useState({
    activeObject: null,
    objects: [
      { id: 1, name: '1. Corporate Documents - required' },
      {
        id: 2,
        name: '2. Management and Personnel i.e. biographies - required',
      },
      {
        id: 3,
        name: '3. Previous Securities Issuance Documents - if applicable',
      },
      { id: 4, name: '4. Financial Information - required' },
      {
        id: 5,
        name: '5. Marketing & Offering Documents - required',
      },
      {
        id: 6,
        name: '6. Contracts and Agreements - if applicable',
      },
      { id: 7, name: '7. Competitive Analysis - optional', isRequired: false },
      { id: 8, name: '8. Legal Matters - if applicable', isRequired: false },
    ],
  });

  const [marketFolders, setMarketingFolder] = useState([
    { id: 1, name: 'Escrow', isRequired: false },
    { id: 2, name: 'Executive Summary', isRequired: false },
    { id: 3, name: 'FAQs - if applicable', isRequired: false },
    { id: 4, name: 'Investor Slide Deck', isRequired: false },
    {
      id: 5,
      name: 'Private Placement Memorandum - if applicable',
      isRequired: false,
    },
    { id: 6, name: 'Risk Matrix', isRequired: false },
    { id: 7, name: 'Subscription Agreement', isRequired: false },
    { id: 8, name: 'Term Sheet', isRequired: false },
  ]);

  const dispatch = useDispatch();

  const getIssuerCompany = async () => {
    if (localStorage.getItem('authToken')) {
      const issuerId = JSON.parse(localStorage.getItem('issuer')).issuerCompany
        ._id;
      const { data } = await apolloClient.query({
        query: issuerCompany,
        variables: { id: issuerId },
      });

      return data.issuerCompany;
    }
  };

  useEffect(async () => {
    const data = await getIssuerCompany();
    dispatch({ type: 'ISSUER_COMPANY', payload: data });
  }, []);

  const handleMarketClick = (folder, index) => {
    if (folder.name === '5. Marketing & Offering Documents - required') {
      setMarkeingView(true);
    } else toggleActive(index);
  };

  const toggleSidebarMenu = () => setIsSidebarMenuOpen(!isSidebarMenuOpen);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const notify = useNotify();
  const [files, setFiles] = useState([]);
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept:
      'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
    multiple: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        files.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      );
    },
  });

  const removeFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };

  const thumbs = files.map((file, i) => {
    if (file.type === 'application/pdf') {
      return (
        <Grid item md={3} className='p-2' key={file.name}>
          <div className={classes.file_wrapper}>
            <div className={classes.close} onClick={() => removeFile(i)}>
              x
            </div>
            <div className='p-2  bg-white shadow-xxl border-dark card-box overflow-hidden font-size-md rounded-sm '>
              <PictureAsPdfIcon />{' '}
              {file.name.substring(0, 9) + '...' + file.name.slice(-4)}
            </div>
          </div>
        </Grid>
      );
    } else if (
      file.type === 'application/msword' ||
      file.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return (
        <Grid item md={3} className='p-2' key={file.name}>
          <div className={classes.file_wrapper}>
            <div className={classes.close} onClick={() => removeFile(i)}>
              x
            </div>
            <div className='p-2 bg-white shadow-xxl border-dark card-box overflow-hidden font-size-md rounded-sm'>
              <DescriptionIcon />{' '}
              {file.name.substring(0, 9) + '...' + file.name.slice(-4)}
            </div>
          </div>
        </Grid>
      );
    } else if (
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    ) {
      return (
        <Grid item md={3} className='p-2' key={file.name}>
          <div className={classes.file_wrapper}>
            <div className={classes.close} onClick={() => removeFile(i)}>
              x
            </div>
            <div className='p-2 bg-white shadow-xxl border-dark card-box overflow-hidden wi font-size-md rounded-sm'>
              <NoteAddIcon />{' '}
              {file.name.substring(0, 9) + '...' + file.name.slice(-4)}
            </div>
          </div>
        </Grid>
      );
    } else if (file.type === 'text/csv') {
      return (
        <Grid item md={3} className='p-2' key={file.name}>
          <div className={classes.file_wrapper}>
            <div className={classes.close} onClick={() => removeFile(i)}>
              x
            </div>
            <div className='p-2 bg-white shadow-xxl border-dark card-box overflow-hidden font-size-md rounded-sm'>
              <SubjectIcon />{' '}
              {file.name.substring(0, 9) + '...' + file.name.slice(-4)}
            </div>
          </div>
        </Grid>
      );
    } else {
      return (
        <Grid item md={3} className='p-2' key={file.name}>
          <div className={classes.file_wrapper}>
            <div className={classes.close} onClick={() => removeFile(i)}>
              x
            </div>
            <div className='p-2 bg-white shadow-xxl border-dark card-box overflow-hidden font-size-md rounded-sm'>
              <PermMediaIcon />{' '}
              {file.name.substring(0, 8) + '..' + file.name.slice(-4)}
            </div>
          </div>
        </Grid>
      );
    }
  });

  const toggleActive = (index) => {
    setFolder({ ...folders, activeObject: folders.objects[index] });
  };

  const toggleActiveStyles = (index) => {
    if (folders.objects[index] === folders.activeObject) {
      return true;
    } else {
      return false;
    }
  };

  const marketingFolderView = marketFolders.map((folder, index) => (
    <Grid key={folder.id} item xl={6} l={12} sm={12} md={6}>
      <div
        onClick={(e) => {
          setFiles([]);
          handleMarketClick(folder, index);
          e.preventDefault();
          setSelectedFolder(!selectedFolder);
          setFolderPath(
            `5. Marketing & Offering Documents - required/${folder.name}`
          );
        }}
        className={
          selectedFolder
            ? `card bg-white btn hover-scale-sm rounded text-left p-4 ${
                toggleActiveStyles(index) && classes.active
              }`
            : 'card bg-white btn hover-scale-sm rounded text-left p-4'
        }
      >
        <div className='d-flex align-items-center'>
          <div className='d-70 rounded bg-neutral-first text-dark mr-3 text-center'>
            <FolderTwoToneIcon className='w-50 h-50' />
          </div>
          <div className='flex-grow-1 text-black-50'>
            <div className='text-black font-weight-bold'>
              {folder.name}
              {folder.isRequired && (
                <span className='text-red'> required*</span>
              )}
            </div>
            {/* <div className='font-size-sm mt-2'>
              Last Updated:{' '}
              <span className='text-black'>moments ago</span>
            </div> */}
          </div>
          <div className='font-size-sm opacity-5'>
            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
          </div>
        </div>
      </div>
    </Grid>
  ));

  const mainView = folders.objects.map((folder, index) => (
    <Grid key={folder.id} item xl={6} l={12} sm={12} md={6}>
      <div
        onClick={(e) => {
          setFiles([]);
          handleMarketClick(folder, index);
          e.preventDefault();
          setSelectedFolder(!selectedFolder);
          setFolderPath(folder.name);
        }}
        className={
          selectedFolder
            ? `card bg-white btn hover-scale-sm rounded text-left p-4 ${
                toggleActiveStyles(index) && classes.active
              }`
            : 'card bg-white btn hover-scale-sm rounded text-left p-4'
        }
      >
        <div className='d-flex align-items-center'>
          <div className='d-70 rounded bg-neutral-first text-dark mr-3 text-center'>
            <FolderTwoToneIcon className='w-50 h-50' />
          </div>
          <div className='flex-grow-1 text-black-50'>
            <div className='text-black font-weight-bold'>
              {folder.name.replace('required', '')}
              {folder.name.includes('required') && (
                <span className='text-red'> required*</span>
              )}
            </div>
            {/* <div className='font-size-sm mt-2'>
              Last Updated:{' '}
              <span className='text-black'>moments ago</span>
            </div> */}
          </div>
          <div className='font-size-sm opacity-5'>
            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
          </div>
        </div>
      </div>
    </Grid>
  ));

  const handleUpload = async () => {
    try {
      const issuerName = JSON.parse(localStorage.getItem('issuer'))
        .issuerCompany.name;

      setUploading(true);
      let formData = new FormData();
      let path = `${issuerName}/Internal Due Diligence/${folderPath}`;
      const issuerId = JSON.parse(localStorage.getItem('issuer')).issuerCompany
        ._id;
      files.forEach((file) => {
        formData.append('files', file);
      });
      formData.append('path', path);
      formData.append('issuerId', issuerId);

      const { data } = await axios.post(
        process.env.REACT_APP_DOCUMENT_UPLOAD,
        formData,
        {
          headers: { 'Access-Control-Allow-Origin': '*' },
        }
      );
      setSelectedFolder(true);
      if (data) {
        setUploading(false);
        notify(data.msg, { type: 'success' });
        setSelectedFolder(false);
        setFolder((folders.activeObject = null));
        setFiles([]);
      }
      setFiles([]);
    } catch (error) {
      setUploading(false);
      notify('Something went wrong please try later', { type: 'error' });
    }
  };

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className='app-inner-content-layout app-inner-content-layout-fixed'>
      {/*<div className='btn-md-pane d-lg-none px-4 order-0'>
        <Button
          onClick={toggleSidebarMenu}
          size='small'
          className='btn-primary p-0 btn-icon d-40'
        >
          <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
        </Button>
  </div>*/}
      <div
        className={clsx(
          'app-inner-content-layout--sidebar bg-white app-inner-content-layout--sidebar__lg order-1',
          { 'layout-sidebar-open': isSidebarMenuOpen }
        )}
      ></div>
      <div className='app-inner-content-layout--main order-3 order-lg-2 card-box bg-secondary'>
        <PerfectScrollbar>
          <div className='card-header rounded-0 bg-white px-5 py-4 border-bottom'>
            <Container className='d-block text-center py-3 text-sm-left d-sm-flex align-items-center justify-content-between'>
              <div className='font-weight-bold font-size-xxl mb-3 mb-sm-0'>
                <div>Document Submission System</div>
                <p className='text-black-50 font-weight-normal font-size-lg mb-0'>
                  Secure document portal for issuers
                </p>
              </div>
            </Container>
          </div>
          <div className='pt-5 pb-2'></div>
          <div className='card-header rounded-0 bg-white px-5 py-4 border-bottom'>
            <Container className='d-block text-center py-3 text-sm-left d-sm-flex align-items-center justify-content-between'>
              <div className='font-weight-bold font-size-xxl mb-3 mb-sm-0 '>
                <p className='font-weight-normal font-size-lg py-3'>
                  Welcome to the Issuer Portal and Document Submission System.
                  Please click the button below to download the Document Request
                  List which outlines the types of documents we need to have on
                  file for compliance purposes. The Request List is organized
                  using the same structure as the folders you see below. Please
                  upload the relevant documents into their respective folders
                  below. Every category might not be relevant for your offering
                  and that is ok. Our team will review the materials you provide
                  and reach out if there is anything additional we need. <br />
                  <br />
                  Your offering dashboard will populate once the due diligence
                  process is complete. This portion of the portal allows you to
                  create your offering and make edits to your company profile
                  and management team members for display on OfferBoard. Please
                  contact Morgan Sills (msills@entoro.com) or Mei Piao
                  (mpiao@entoro.com) if you have any questions or issues.
                </p>
                <div className='d-flex justify-content-center'>
                  <a
                    className={classes.pdf_btn}
                    href='Entoro Due Diligence Document Request List for Issuer Portal.pdf'
                    download
                  >
                    Document Request List
                  </a>
                </div>
                {marketingView && (
                  <div
                    className='d-flex justify-content-right'
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedFolder(false);
                      setMarkeingView(false);
                    }}
                  >
                    <div className='d-flex justify-items-center small hover-scale-lg '>
                      <FontAwesomeIcon
                        style={{
                          marginTop: '0.3rem',
                          marginRight: '0.2rem',
                          backgroundColor: '#000',
                          width: '1.3rem',
                          color: '#fff',
                          borderRadius: '50%',
                        }}
                        icon={['fas', 'arrow-left']}
                      />{' '}
                      <p>Go back</p>
                    </div>
                  </div>
                )}
              </div>
            </Container>
          </div>
          <div className='pt-1 pb-3'>
            <Container>
              <Card className='rounded-lg shadow-xxl p-3 my-5'>
                <List className='d-flex nav-tabs justify-content-center nav-tabs-first tabs-animated tabs-animated-shadow'>
                  <ListItem>
                    <span className='font-weight-bold text-uppercase font-size-sm'>
                      Internal Due Diligence Folders
                    </span>
                  </ListItem>
                </List>
              </Card>
              {selectedFolder && (
                <Card className='my-5 shadow-xxl'>
                  <div className='p-3 p-lg-5'>
                    <div className='dropzone'>
                      <div
                        {...getRootProps({
                          className: 'dropzone-upload-wrapper',
                        })}
                      >
                        <input {...getInputProps()} />
                        <div className='dropzone-inner-wrapper bg-white'>
                          {isDragAccept && (
                            <div>
                              <div className='d-140 hover-scale-lg icon-blob icon-blob-animated btn-icon text-success mx-auto'>
                                <svg
                                  className='d-140 opacity-2'
                                  viewBox='0 0 600 600'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <g transform='translate(300,300)'>
                                    <path
                                      d='M170.4,-137.2C213.2,-82.3,234.8,-11.9,223.6,56.7C212.4,125.2,168.5,191.9,104.3,226.6C40.2,261.3,-44.1,264,-104,229.8C-163.9,195.7,-199.4,124.6,-216.2,49.8C-233,-25.1,-231,-103.9,-191.9,-158C-152.7,-212.1,-76.4,-241.6,-6.3,-236.6C63.8,-231.6,127.7,-192.2,170.4,-137.2Z'
                                      fill='currentColor'
                                    />
                                  </g>
                                </svg>
                                <div className='blob-icon-wrapper'>
                                  <CheckIcon className='d-50' />
                                </div>
                              </div>
                              <div className='font-size-sm text-success'>
                                All files will be uploaded!
                              </div>
                            </div>
                          )}
                          {isDragReject && (
                            <div>
                              <div className='d-140 hover-scale-lg icon-blob icon-blob-animated btn-icon text-danger mx-auto'>
                                <svg
                                  className='d-140 opacity-2'
                                  viewBox='0 0 600 600'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <g transform='translate(300,300)'>
                                    <path
                                      d='M169,-144C206.7,-87.5,216.5,-18,196.9,35.7C177.3,89.4,128.3,127.1,75.2,150.7C22,174.2,-35.4,183.5,-79.7,163.1C-124,142.7,-155.1,92.6,-164.1,40.9C-173.1,-10.7,-160.1,-64,-129,-118.9C-98,-173.8,-49,-230.4,8.3,-237.1C65.7,-243.7,131.3,-200.4,169,-144Z'
                                      fill='currentColor'
                                    />
                                  </g>
                                </svg>
                                <div className='blob-icon-wrapper'>
                                  <CloseTwoToneIcon className='d-50' />
                                </div>
                              </div>
                              <div className='font-size-sm text-danger'>
                                Unsupported files will be rejected
                              </div>
                            </div>
                          )}
                          {!isDragActive && (
                            <div>
                              <div className='d-140 hover-scale-lg icon-blob btn-icon text-first mx-auto'>
                                <svg
                                  className='d-140 opacity-2'
                                  viewBox='0 0 600 600'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <g transform='translate(300,300)'>
                                    <path
                                      d='M171.2,-128.5C210.5,-87.2,223.2,-16.7,205.1,40.4C186.9,97.5,137.9,141.1,81.7,167.2C25.5,193.4,-38,202.1,-96.1,181.2C-154.1,160.3,-206.7,109.7,-217.3,52.7C-227.9,-4.4,-196.4,-68,-153.2,-110.2C-110,-152.4,-55,-173.2,5.5,-177.5C65.9,-181.9,131.9,-169.8,171.2,-128.5Z'
                                      fill='currentColor'
                                    />
                                  </g>
                                </svg>
                                <div className='blob-icon-wrapper'>
                                  <PublishTwoToneIcon className='d-50' />
                                </div>
                              </div>
                              <div className='font-size-sm'>
                                Drag some files!
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer p-4 bg-secondary'>
                    <div>
                      <div className='font-weight-bold mb-3 text-uppercase text-dark font-size-sm text-center'>
                        Uploaded Files
                      </div>
                      {thumbs.length <= 0 && (
                        <div className='text-first text-center font-size-sm'>
                          Uploaded document previews will appear here!
                        </div>
                      )}
                      {thumbs.length > 0 && (
                        <div>
                          {/* <Alert
                            severity='success'
                            className='text-center mb-3'
                          >
                            You have uploaded <b>{thumbs.length}</b> files!
                          </Alert> */}
                          <Grid container spacing={0}>
                            {thumbs}
                          </Grid>

                          <Button
                            variant='contained'
                            style={{
                              backgroundColor: '#4091FF',
                              color: '#fff',
                              width: '100%',
                              height: '3rem',
                            }}
                            onClick={(e) => {
                              handleUpload();
                            }}
                          >
                            {uploading ? 'Uploading... please wait' : 'Upload'}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              )}
              <div className='mb-spacing-6-x2'>
                <div
                  className={clsx('tab-item-wrapper overflow-visible d-none', {
                    'd-block active': activeTab === '0',
                  })}
                  index={0}
                >
                  <Grid container spacing={4}>
                    {marketingView ? marketingFolderView : mainView}
                  </Grid>
                </div>
              </div>
            </Container>
          </div>
        </PerfectScrollbar>
      </div>
      <div
        onClick={toggleSidebarMenu}
        className={clsx('sidebar-inner-layout-overlay', {
          active: isSidebarMenuOpen,
        })}
      />
    </div>
  );
};

export default FolderDocumentDashboard;
