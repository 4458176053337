import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'react-image-crop/dist/ReactCrop.css';
import { showNotification as showNotificationAction } from 'react-admin';
import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_UPLOAD_PRESET } from '../config';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

const styles = {
  uploadButtonStyle: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    width: '100%',
    height: '100%',
    opacity: '0',
    zIndex: 10,
  },
  isUploadingStyle: {
    position: 'absolute',
    zIndex: 1,
    opacity: 0.6,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgb(80,80,80)',
  },
  imageStyle: {
    border: '1px solid #EEE',
    marginBottom: '10px',
    maxHeight: '300px',
  },
  button: {
    width: 'auto',
    margin: '10px 10px 10px 0px',
  },
  encodedFile: {
    position: 'relative',
    display: 'inline-flex',
  },
  noticeStyle: {
    fontSize: '13px',
    color: '#F44336',
    margin: '0',
  },
};

const encodeFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

class PDFInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileType: null,
      encodedFile: null,
      isUploading: false,
    };
  }

  onChange = async (event) => {
    const [firstFile] = event.target.files;

    if (!firstFile) {
      return;
    }

    const encodedFile = await encodeFile(firstFile);

    this.setState({
      fileType: firstFile.type,
      encodedFile,
    });
  };

  uploadPDF = async (file) => {
    const {
      showNotification,
      input: { onChange },
    } = this.props;

    try {
      this.setState({ isUploading: true });

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/`,
        {
          file,
          // https://cloudinary.com/documentation/image_upload_api_reference#upload
          // Upload preset required for unsigned uploading
          upload_preset: CLOUDINARY_UPLOAD_PRESET,
          secure: true,
        }
      );

      this.setState(
        { isUploading: false, encodedFile: null, crop: null },
        () => {
          onChange(response.data.secure_url);
          showNotification('PDF uploaded');
        }
      );
    } catch (error) {
      this.setState({ isUploading: false }, () => {
        // eslint-disable-line no-console
        showNotification(error.message);
      });
    }
  };

  updateCrop = (crop) => {
    this.setState({ crop });
  };

  savePDF = () => {
    const { encodedFile, fileType } = this.state;

    if (fileType === 'application/pdf') {
      this.uploadPDF(encodedFile);
    }
  };

  renderPDF() {
    const {
      input: { value },
      meta: { touched, error },
    } = this.props;
    const { isUploading, encodedFile, fileType } = this.state;

    if (encodedFile) {
      return (
        <div>
          <div style={styles.encodedFile}>
            {isUploading && (
              <div style={styles.isUploadingStyle}>
                <CircularProgress />
              </div>
            )}

            {fileType === 'application/pdf' && (
              <embed
                src={encodedFile}
                type='application/pdf'
                style={styles.imageStyle}
                width='500'
                height='500'
              />
            )}
          </div>
          {touched && error ? <p style={styles.noticeStyle}>{error}</p> : null}
        </div>
      );
    }

    if (!value) {
      return touched && error ? (
        <p style={styles.noticeStyle}>{error}</p>
      ) : null;
    }

    return (
      <div>
        <embed
          src={value}
          type='application/pdf'
          style={styles.imageStyle}
          width='500'
          height='500'
        />
        <p style={styles.noticeStyle}>
          * Be sure to save this form if adding new PDF file
        </p>
      </div>
    );
  }

  render() {
    const { recommendedAspect, disabledField } = this.props;
    const { encodedFile, isUploading } = this.state;

    return (
      <MuiThemeProvider>
        <div>
          {!disabledField && (
            <div>
              {encodedFile ? (
                <div style={{ display: 'block' }}>
                  <RaisedButton
                    variant='outlined'
                    disabled={isUploading}
                    style={styles.button}
                    onClick={() => this.setState({ encodedFile: null })}
                  >
                    Cancel
                  </RaisedButton>
                  <RaisedButton
                    variant='outlined'
                    disabled={isUploading}
                    style={styles.button}
                    onClick={this.savePDF}
                  >
                    Confirm
                  </RaisedButton>
                </div>
              ) : (
                <div>
                  <RaisedButton style={styles.button} label='Select PDF file'>
                    <input
                      type='file'
                      accept='application/pdf'
                      style={styles.uploadButtonStyle}
                      onChange={this.onChange}
                    />
                  </RaisedButton>
                  {
                    <p
                      style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: '11px' }}
                    >
                      Recommended aspect ratio:{' '}
                      <strong>{recommendedAspect}</strong>
                    </p>
                  }
                </div>
              )}
            </div>
          )}
          {this.renderPDF()}
        </div>
      </MuiThemeProvider>
    );
  }
}

const FieldWrapper = ({
  source,
  aspect,
  recommendedAspect,
  showNotification,
  validate,
  disabled,
  disabledField,
}) => (
  <Field
    name={source}
    disabled={disabled}
    component={PDFInput}
    aspect={aspect}
    showNotification={showNotification}
    recommendedAspect={recommendedAspect}
    validate={validate}
    disabledField={disabledField}
  />
);

PDFInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  aspect: PropTypes.number,
  recommendedAspect: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

PDFInput.defaultProps = {
  aspect: undefined,
  recommendedAspect: undefined,
};

FieldWrapper.propTypes = {
  source: PropTypes.string.isRequired,
  aspect: PropTypes.number,
  recommendedAspect: PropTypes.string,
  showNotification: PropTypes.func.isRequired,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
};

FieldWrapper.defaultProps = {
  aspect: undefined,
  recommendedAspect: undefined,
  validate: null,
  disabled: false,
  disabledField: false,
};

export default connect(null, {
  showNotification: showNotificationAction,
})(FieldWrapper);
