import * as React from 'react';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import ContentCreate from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import { linkToRecord, useResourceContext } from 'ra-core';

import Button from './Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Opens the Edit view of a given record:
 *
 * @example // basic usage
 * import { EditButton } from 'react-admin';
 *
 * const CommentEditButton = ({ record }) => (
 *     <EditButton basePath="/comments" label="Edit comment" record={record} />
 * );
 */
const EditButton = (props) => {
  const {
    basePath = '',
    icon = defaultIcon,
    record,
    scrollToTop = true,
    ...rest
  } = props;
  const resource = useResourceContext();
  return (
    <Button
      component={Link}
      to={useMemo(
        () => ({
          pathname: record
            ? linkToRecord(basePath || `/${resource}`, record.id)
            : '',
          state: { _scrollToTop: scrollToTop },
        }),
        [basePath, record, resource, scrollToTop]
      )}
      className='btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center'
      onClick={stopPropagation}
      {...rest}
    >
      <FontAwesomeIcon icon={['far', 'edit']} className='font-size-sm' />
    </Button>
  );
};

const defaultIcon = <ContentCreate />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e) => e.stopPropagation();

EditButton.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  record: PropTypes.any,
  scrollToTop: PropTypes.bool,
};

export default EditButton;
