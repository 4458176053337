import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import {
  formatCurrency,
  formatNumber,
  formatShares,
  parseCurrency,
  parseNumber,
} from 'entoro-shared';

const TextFieldWrapper = ({
  required,
  label,
  input,
  meta: { touched, error },
  resource,
  disabledField,
}) => (
  <TextField
    {...input}
    variant='outlined'
    fullWidth
    disabled={disabledField}
    resource={resource}
    label={required ? `${label} *` : label}
    errorText={Boolean(touched && error) && error}
  />
);

TextFieldWrapper.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

const createNumberInput = ({ format, parse }) => {
  const NumberInputWrapper = ({
    source,
    validate,
    label,
    required,
    otherProps,
    resource,
    disabledField,
  }) => (
    <Field
      name={source}
      validate={validate}
      label={label}
      required={required}
      component={TextFieldWrapper}
      format={format}
      parse={parse}
      disabledField={disabledField}
      {...otherProps}
      resource={resource}
    />
  );

  NumberInputWrapper.propTypes = {
    source: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    validate: PropTypes.func,
    required: PropTypes.bool,
  };

  NumberInputWrapper.defaultProps = {
    validate: null,
    required: false,
    disabledField: false,
  };

  return NumberInputWrapper;
};

export const NumberInput = createNumberInput({
  format: formatNumber,
  parse: parseNumber,
});

export const CurrencyInput = createNumberInput({
  format: formatCurrency,
  parse: parseCurrency,
});

export const SharesInput = createNumberInput({
  format: formatShares,
  parse: parseNumber,
});
