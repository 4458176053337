import React from 'react';

import FolderDocumentDashboard from '../DocumentDashboard/FolderDocumentDashboard';

import LayoutWithoutTheme from '../../../Layout/BaseLayout';

export default function DocumentDashboard() {
  return (
    <LayoutWithoutTheme>
      <FolderDocumentDashboard />
    </LayoutWithoutTheme>
  );
}
