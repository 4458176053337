import React from 'react';

import { RichTextInput } from 'utility/RicTextInput';

import { Container, Card, Grid } from '@material-ui/core';
import { includes } from 'lodash';

import {
  TextInput,
  FormWithRedirect,
  FormDataConsumer,
  Toolbar,
  DateInput,
  NumberInput as AorNumberInput,
  ArrayInput,
  SimpleFormIterator,
  NullableBooleanInput,
} from 'react-admin';

import {
  bankPaymentTypes,
  cryptoPaymentTypes,
  areas,
  investmentInstruments,
  listingTypes,
  offerPublishedStatuses,
  sectors,
  url,
  paymentMethods,
  openEndFunds,
  redemptionOptions,
  fundTypes,
  fundStructureOptions,
  geographicMandates,
  systematicDiscretionaryOptions,
  investmentCoActExemptionOptions,
  investmentAdvisorTypes,
  domicileOptions,
  domicileOffShoreFund,
  domicileOnshoreFund,
  baseCurrencies,
  offerTypes,
  processTypes,
  headerTypes,
} from 'entoro-shared';
import ImageInput from 'utility/ImageInput';
import InvestmentBooleanInput from 'utility/InvestmentBooleanInput';
import ExpandSection from 'utility/ExpandSection';
import FunctionField from 'utility/FunctionField';
import { SelectArrayInput, SelectInput } from 'utility/SelectInput';
import { SimpleForm } from 'utility/components/form';
import { CurrencyInput, SharesInput } from 'utility/number-nput';
import ListingTypeSelectInput from 'utility/ListingTypeSelectInput';
import SectorSelectInput from 'utility/SectorSelectInput';
import PDFInput from 'utility/PDFInput';

import { required } from 'utility/validate';
const onlyNumber = (value) => {
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums;
};

const UserEditToolbar = (props) => <Toolbar {...props}></Toolbar>;
const OfferForm = ({
  allowEmpty,
  permissions,
  disabledField = false,
  record,
  name,
  ...props
}) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        // here starts the custom form layout
        <SimpleForm
          toolbar={!disabledField && <UserEditToolbar />}
          record={record}
          {...props}
        >
          <>
            <Card>
              <div className='card-header d-flex align-items-center justify-content-between card-header-alt p-0'></div>
              <label className='font-weight-bold py-2 ml-4'>
                {name.toUpperCase()}
              </label>
              <div className='px-0 py-0 py-lg-4'>
                <div index={1}>
                  <Container>
                    <ExpandSection name='1. Offer Details' />

                    <Grid container spacing={3}>
                      <Grid item md={12}>
                        <FormDataConsumer dependsOn='previewUrl'>
                          {/* <LongTextInput */}
                          {({ formData, ...rest }) =>
                            formData.previewUrl && (
                              <>
                                <label className='font-weight-bold mb-2'>
                                  Preview url
                                </label>
                                <br />

                                <FunctionField
                                  source='previewUrl'
                                  disabled={disabledField}
                                  label='Preview Url'
                                  render={(previewUrl) => (
                                    <a href={previewUrl} target='_blank'>
                                      {previewUrl}
                                    </a>
                                  )}
                                />
                              </>
                            )
                          }
                        </FormDataConsumer>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <div className='mb-12'>
                          <label className='font-weight-bold mb-2'>Name</label>
                          <TextInput
                            variant='outlined'
                            fullWidth
                            source='name'
                            resource='offer'
                            disabled={disabledField}
                            label='Name'
                            validate={required}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <ExpandSection name='2. Type of Offer'>
                      <div className='py-4'>
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <label className='font-weight-bold mb-2'>
                              Type Of Offer
                            </label>
                            <div className='mb-2'>
                              <SelectInput
                                source='typeOfOffer'
                                label='Type Of Offer'
                                variant='outlined'
                                disabled={disabledField}
                                fullWidth
                                resource='offer'
                                allowEmpty
                                options={offerTypes.options}
                              />
                              <FormDataConsumer
                                dependsOn='typeOfOffer'
                                value='Reg_D_506b'
                              >
                                {({ formData, ...rest }) =>
                                  formData.typeOfOffer == 'Reg_D_506b' && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        506b alternative title
                                      </label>
                                      <TextInput
                                        validate={required}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        source='altOfferTitle'
                                        disabled={disabledField}
                                        label='506b alternative title'
                                      />
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer
                                dependsOn='typeOfOffer'
                                value='REG_A'
                              >
                                {({ formData, ...rest }) =>
                                  formData.typeOfOffer == 'REG_A' && (
                                    <SelectInput
                                      source='processType'
                                      variant='outlined'
                                      fullWidth
                                      disabled={disabledField}
                                      resource='offer'
                                      options={processTypes.options}
                                      validate={required}
                                    />
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer
                                dependsOn='typeOfOffer'
                                value='REG_CF'
                              >
                                {({ formData, ...rest }) =>
                                  formData.typeOfOffer == 'REG_CF' && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        Wefunder Process Type
                                      </label>
                                      <div className='m-2'>
                                        <NullableBooleanInput
                                          style={{ width: '100%' }}
                                          source='wefunderProcessType'
                                          label='Wefunder Process Type'
                                          disabled={disabledField}
                                        />
                                      </div>
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer dependsOn='wefunderProcessType'>
                                {/* <LongTextInput */}
                                {({ formData, ...rest }) =>
                                  formData.wefunderProcessType && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        Wefunder Redirect url
                                      </label>
                                      <TextInput
                                        validate={required}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        source='wefunderUrl'
                                        disabled={disabledField}
                                        label='Wefunder Redirect url'
                                      />

                                      <FunctionField
                                        source='wefunderPreviewUrl'
                                        disabled={disabledField}
                                        label='Wefunder Preview Url'
                                        render={(wefunderPreviewUrl) => (
                                          <a
                                            href={wefunderPreviewUrl}
                                            target='_blank'
                                          >
                                            {wefunderPreviewUrl}
                                          </a>
                                        )}
                                      />
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer
                                dependsOn='typeOfOffer'
                                value='REG_CF'
                              >
                                {({ formData, ...rest }) =>
                                  formData.typeOfOffer == 'REG_CF' && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        External Process Type
                                      </label>
                                      <div className='m-2'>
                                        <NullableBooleanInput
                                          style={{ width: '100%' }}
                                          source='realexProcessType'
                                          label='External Process Type'
                                          disabled={disabledField}
                                        />
                                      </div>
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer dependsOn='realexProcessType'>
                                {({ formData, ...rest }) =>
                                  formData.realexProcessType && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        External Process type Redirect url
                                      </label>
                                      <TextInput
                                        validate={required}
                                        source='realexUrl'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        label='External Process type Redirect url'
                                        disabled={disabledField}
                                      />

                                      <FunctionField
                                        source='realexPreviewUrl'
                                        label='External Process type Preview Url'
                                        disabled={disabledField}
                                        render={(realexPreviewUrl) => (
                                          <a
                                            href={realexPreviewUrl}
                                            target='_blank'
                                          >
                                            {realexPreviewUrl}
                                          </a>
                                        )}
                                      />
                                    </>
                                  )
                                }
                              </FormDataConsumer>

                              <FormDataConsumer
                                dependsOn='typeOfOffer'
                                value='REG_CF'
                              >
                                {({ formData, ...rest }) =>
                                  formData.typeOfOffer == 'REG_CF' && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        Republic Process Type
                                      </label>
                                      <div className='m-2'>
                                        <NullableBooleanInput
                                          style={{ width: '100%' }}
                                          source='republicProcessType'
                                          label='Republic Process Type'
                                          disabled={disabledField}
                                        />
                                      </div>
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer>
                                {/* <LongTextInput */}
                                {({ formData, ...rest }) =>
                                  formData.republicProcessType && (
                                    <>
                                      <label className='font-weight-bold mb-2'>
                                        Republic Redirect url
                                      </label>
                                      <TextInput
                                        validate={required}
                                        source='republicUrl'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        label='Republic Redirect url'
                                        disabled={disabledField}
                                      />

                                      <FunctionField
                                        source='republicPreviewUrl'
                                        label='Republic Preview Url'
                                        disabled={disabledField}
                                        render={(republicPreviewUrl) => (
                                          <a
                                            href={republicPreviewUrl}
                                            target='_blank'
                                          >
                                            {republicPreviewUrl}
                                          </a>
                                        )}
                                      />
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                              <FormDataConsumer
                                dependsOn='typeOfOffer'
                                value='REG_CF'
                              >
                                {({ formData, ...rest }) =>
                                  formData.typeOfOffer == 'REG_CF' && (
                                    <>
                                      <DateInput
                                        // format={dateFormatter}
                                        // parser={dateParser}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        label='Count Downs'
                                        source='countDown'
                                        disabled={disabledField}
                                      />
                                      <AorNumberInput
                                        parse={(v) => {
                                          //if (v < 1) return;
                                          if (v > 100) return;
                                          return v;
                                        }}
                                        source='cashFee'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        label='Cash Fee %'
                                        disabled={disabledField}
                                      />
                                      <AorNumberInput
                                        parse={(v) => {
                                          //if (v < 1) return;
                                          if (v > 100) return;
                                          return v;
                                        }}
                                        source='securitiesCommission'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        label='Securities Commision %'
                                        disabled={disabledField}
                                      />
                                      <DateInput
                                        // format={dateFormatter}
                                        //parser={dateParser}
                                        label='Closing Date'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        source='closingDate'
                                        disabled={disabledField}
                                      />
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                            </div>{' '}
                          </Grid>{' '}
                        </Grid>
                      </div>
                    </ExpandSection>
                  </Container>

                  {/* <Container>
                    <ExpandSection name='3. Offer Status'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={6}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Visibility Status
                              </label>
                              <SelectInput
                                source='status'
                                variant='outlined'
                                fullWidth
                                defaultValue='PREMARKETING'
                                resource='offer'
                                options={offerStatuses.options}
                                validate={required}
                                disabled={disabledField}
                              />
                            </div>
                          </Grid>

                          <Grid item md={6}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Type and press the Enter key to add an email to
                                the list
                              </label>
                              <SelectArrayInput
                                source='bankerEmails'
                                label='Banker Emails'
                                variant='outlined'
                                fullWidth
                                styleOptions={{
                                  fullWidth: true,
                                  listStyle: { maxHeight: 250 },
                                }}
                                resource='offer'
                                disabledField={disabledField}
                                //validate={required}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container> */}

                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.isApproveIssuerOffer && (
                        <Container>
                          <ExpandSection name=' Offer Visibility'>
                            <div className='py-4'>
                              {' '}
                              <Grid container spacing={6}>
                                <Grid item md={12}>
                                  <div className='mb-4'>
                                    <label className='font-weight-bold mb-2'>
                                      Offer Visibility
                                    </label>
                                    <SelectInput
                                      source='visibility'
                                      label='Visibility'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      options={offerPublishedStatuses.options}
                                      validate={required}
                                      disabled={disabledField}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>{' '}
                          </ExpandSection>
                        </Container>
                      )
                    }
                  </FormDataConsumer>

                  <Container>
                    <ExpandSection name='4.  Tombstone Image'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Tombstone Image
                              </label>
                              <ImageInput
                                source='tombstoneImageId'
                                addLabel
                                label='Tombstone Image'
                                aspect={4 / 3}
                                recommendedAspect='recommended minimum image width: 200px'
                                disabledField={disabledField}
                                //validate={required}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>

                  <Container>
                    <ExpandSection name='5.  Header '>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Header
                              </label>
                              <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                  <SelectInput
                                    source='headerType'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    defaultValue={
                                      formData.headerType !== 'IMAGE'
                                        ? 'VIDEO'
                                        : 'IMAGE'
                                    }
                                    options={headerTypes.options}
                                    disabled={disabledField}
                                  />
                                )}
                              </FormDataConsumer>
                              <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                  formData.headerType !== 'VIDEO' && (
                                    <ImageInput
                                      source='headerImageId'
                                      addLabel
                                      label='Header Image'
                                      aspect={3 / 2}
                                      recommendedAspect='recommended minimum image width: 400px'
                                      disabledField={disabledField}
                                    />
                                  )
                                }
                              </FormDataConsumer>

                              <FormDataConsumer
                                dependsOn='headerType'
                                resolve={(value) => value === 'VIDEO'}
                              >
                                {({ formData, ...rest }) =>
                                  formData.headerType === 'VIDEO' && (
                                    <TextInput
                                      source='headerVideoId'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      label='Header Video(Youtube/Mp4 link)'
                                      disabled={disabledField}
                                    />
                                  )
                                }
                              </FormDataConsumer>
                            </div>
                          </Grid>
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>

                  <Container>
                    <ExpandSection name='6. Overview'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={6}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Listing Type
                              </label>
                              <ListingTypeSelectInput
                                source='listingType'
                                variant='outlined'
                                fullWidth
                                resource='offer'
                                options={listingTypes.options}
                                validate={required}
                                disabled={disabledField}
                              />
                            </div>

                            <FormDataConsumer>
                              {({ formData, ...rest }) =>
                                (formData.investmentInstrument === 'SAFE' ||
                                  formData.investmentInstrument ===
                                    'CONVERTIBLE_NOTE') &&
                                formData.typeOfOffer === 'REG_CF' && (
                                  <>
                                    <CurrencyInput
                                      source='valuationCap'
                                      resource='offer'
                                      label='Valuation Cap'
                                      disabledField={disabledField}
                                    />
                                    <AorNumberInput
                                      parse={(v) => {
                                        //if (v < 1) return;
                                        if (v > 100) return;
                                        return v;
                                      }}
                                      source='discountRate'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      label='Discount Rate %'
                                      disabled={disabledField}
                                    />
                                    <AorNumberInput
                                      min='1'
                                      max='100'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      source='coupon'
                                      parse={(v) => {
                                        if (v > 100) return;
                                        return v;
                                      }}
                                      label='Coupon %'
                                      disabled={disabledField}
                                    />
                                    <DateInput
                                      source='maturity'
                                      label='Maturity'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      disabled={disabledField}
                                    />
                                  </>
                                )
                              }
                            </FormDataConsumer>
                            <br />
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) =>
                                value === 'ACQUISITIONS_AND_DIVESTITURES'
                              }
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType ===
                                  'ACQUISITIONS_AND_DIVESTITURES' && (
                                  <>
                                    <TextInput
                                      source='primaryObjective'
                                      label='Primary Objective'
                                      validate={required}
                                      required
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      disabled={disabledField}
                                    />
                                    <TextInput
                                      source='county'
                                      label='County'
                                      validate={required}
                                      required
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      disabled={disabledField}
                                    />
                                  </>
                                )
                              }
                            </FormDataConsumer>
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) => value !== 'FUNDS'}
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType !== 'FUNDS' && (
                                  <>
                                    <FormDataConsumer>
                                      {({ formData, ...rest }) =>
                                        formData.listingType !==
                                          'ACQUISITIONS_AND_DIVESTITURES' && (
                                          <SelectInput
                                            source='investmentInstrument'
                                            options={
                                              investmentInstruments.options
                                            }
                                            validate={required}
                                            variant='outlined'
                                            fullWidth
                                            resource='offer'
                                            required
                                            disabled={disabledField}
                                          />
                                        )
                                      }
                                    </FormDataConsumer>
                                    <SelectInput
                                      source='area'
                                      options={areas.options}
                                      variant='outlined'
                                      fullWidth
                                      required
                                      resource='offer'
                                      validate={required}
                                      disabled={disabledField}
                                    />
                                    <br />
                                  </>
                                )
                              }
                            </FormDataConsumer>
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) => value === 'FUNDS'}
                            >
                              {({ formData, ...rest }) => {
                                return (
                                  formData.listingType === 'FUNDS' && (
                                    <>
                                      <SelectInput
                                        source='fundStructure'
                                        label='Fund Structure'
                                        options={fundStructureOptions.options}
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />
                                      {/* fund structure starts */}
                                      <FormDataConsumer
                                        dependsOn='fundStructure'
                                        resolve={(value) =>
                                          value === 'OPEN_END_FUND'
                                        }
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.fundStructure ===
                                            'OPEN_END_FUND' && (
                                            <>
                                              <SelectInput
                                                source='openEndFund'
                                                label='Open End Funds'
                                                options={openEndFunds.options}
                                                validate={required}
                                                required={true}
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                disabled={disabledField}
                                              />
                                              <br />
                                              {/* Redemption Funds */}
                                              <FormDataConsumer
                                                dependsOn='openEndFund'
                                                resolve={(value) =>
                                                  value === 'REDEMPTIONS'
                                                }
                                              >
                                                {({ formData, ...rest }) =>
                                                  formData.openEndFund ===
                                                    'REDEMPTIONS' && (
                                                    <>
                                                      <SelectInput
                                                        source='redemption'
                                                        label='Redemption'
                                                        options={
                                                          redemptionOptions.options
                                                        }
                                                        validate={required}
                                                        required={true}
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        m
                                                        disabled={disabledField}
                                                      />
                                                      <FormDataConsumer
                                                        dependsOn='redemption'
                                                        resolve={(value) =>
                                                          value === 'OTHERS'
                                                        }
                                                      >
                                                        {({
                                                          formData,
                                                          ...rest
                                                        }) =>
                                                          formData.redemption ===
                                                            'OTHERS' && (
                                                            <>
                                                              <TextInput
                                                                source='redemption_other'
                                                                label='Enter Redemption Type Others'
                                                                variant='outlined'
                                                                fullWidth
                                                                resource='offer'
                                                                required
                                                                disabled={
                                                                  disabledField
                                                                }
                                                              />
                                                              <br />
                                                            </>
                                                          )
                                                        }
                                                      </FormDataConsumer>
                                                      <br />
                                                    </>
                                                  )
                                                }
                                              </FormDataConsumer>

                                              <FormDataConsumer
                                                dependsOn='openEndFund'
                                                resolve={(value) =>
                                                  value === 'LOCKUP_PERIOD_MOS'
                                                }
                                              >
                                                {({ formData, ...rest }) =>
                                                  formData.openEndFund ===
                                                    'LOCKUP_PERIOD_MOS' && (
                                                    <>
                                                      <CurrencyInput
                                                        source='lockupPeriod'
                                                        label='Lock Up Period Mos'
                                                        validate={required}
                                                        required
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        disabledField={
                                                          disabledField
                                                        }
                                                      />
                                                      <br />
                                                    </>
                                                  )
                                                }
                                              </FormDataConsumer>

                                              {/* Open End Funds ends */}
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <SelectInput
                                        source='fundType'
                                        label='Fund Type'
                                        options={fundTypes.options}
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />

                                      <FormDataConsumer
                                        dependsOn='fundType'
                                        resolve={(value) => value === 'OTHER'}
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.fundType === 'OTHER' && (
                                            <>
                                              <TextInput
                                                source='fundtype_other'
                                                label='Enter Fund Type Others'
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <div>
                                        <label className='font-weight-bold '>
                                          Geographic Mandate
                                        </label>
                                      </div>
                                      <SelectArrayInput
                                        source='geographicMandate'
                                        label='Geographic Mandate'
                                        choices={geographicMandates.options}
                                        allowEmpty={false}
                                        validate={required}
                                        required
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabledField={disabledField}
                                        styleOptions={{
                                          fullWidth: true,
                                          listStyle: { maxHeight: 250 },
                                          readOnly: true,
                                        }}
                                      />

                                      <FormDataConsumer
                                        dependsOn='geographicMandate'
                                        resolve={(value) =>
                                          includes(value, 'OTHER')
                                        }
                                      >
                                        {({ formData, ...rest }) =>
                                          includes(
                                            formData.geographicMandate,
                                            'OTHER'
                                          ) && (
                                            <>
                                              <TextInput
                                                source='graphicmandate_other'
                                                label='Enter Graphic Mandate Others'
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <SelectInput
                                        source='baseCurrency'
                                        label='Base Currency'
                                        options={baseCurrencies.options}
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />
                                      <FormDataConsumer
                                        dependsOn='baseCurrency'
                                        resolve={(value) => value === 'OTHER'}
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.baseCurrency === 'OTHER' && (
                                            <>
                                              <TextInput
                                                source='basecurrency_other'
                                                label='Enter Other Base Currency'
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <SelectInput
                                        source='domicileOption'
                                        label='Domicile Options'
                                        options={domicileOptions.options}
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />

                                      <FormDataConsumer
                                        dependsOn='domicileOption'
                                        resolve={(value) =>
                                          value === 'ONSHORE_FUND'
                                        }
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.domicileOption ===
                                            'ONSHORE_FUND' && (
                                            <>
                                              <SelectInput
                                                source='domicileOnshore'
                                                label='Domicile Onshore Options'
                                                options={
                                                  domicileOnshoreFund.options
                                                }
                                                validate={required}
                                                required={true}
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                disabled={disabledField}
                                              />
                                              <FormDataConsumer
                                                dependsOn='domicileOnshore'
                                                resolve={(value) =>
                                                  value === 'OTHER'
                                                }
                                              >
                                                {({ formData, ...rest }) =>
                                                  formData.domicileOnshore ===
                                                    'OTHER' && (
                                                    <>
                                                      <TextInput
                                                        source='domicileOnshoreFund_other'
                                                        label='Enter Other Onshore Fund'
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <br />
                                                    </>
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>

                                      <FormDataConsumer
                                        dependsOn='domicileOption'
                                        resolve={(value) =>
                                          value === 'OFFSHORE_FUND'
                                        }
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.domicileOption ===
                                            'OFFSHORE_FUND' && (
                                            <>
                                              <SelectInput
                                                source='domicileOffShore'
                                                label='Domicile Offshore Options'
                                                options={
                                                  domicileOffShoreFund.options
                                                }
                                                validate={required}
                                                required={true}
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                disabled={disabledField}
                                              />
                                              <FormDataConsumer
                                                dependsOn='domicileOffShore'
                                                resolve={(value) =>
                                                  value === 'OTHER'
                                                }
                                              >
                                                {({ formData, ...rest }) =>
                                                  formData.domicileOffShore ===
                                                    'OTHER' && (
                                                    <>
                                                      <TextInput
                                                        source='domicileOffShore_other'
                                                        label='Enter Other Offshore Fund'
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <br />
                                                    </>
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>

                                      {/* other list apart from domicile */}
                                      <TextInput
                                        source='fundStrategyClassfication'
                                        label='Fund Investment Strategy'
                                        maxLength='50'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        required
                                        disabled={disabledField}
                                      />
                                      <TextInput
                                        source='fundStrategyClassficationOne'
                                        label='Fund Strategy Classification: Strategy #1'
                                        maxLength='50'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        required
                                        disabled={disabledField}
                                      />
                                      <TextInput
                                        source='fundStrategyClassficationTwo'
                                        label='Fund Strategy Classification: Strategy #2'
                                        maxLength='50'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        required
                                        disabled={disabledField}
                                      />
                                      <TextInput
                                        source='fundStrategyClassficationThree'
                                        label='Fund Strategy Classification: Strategy #3'
                                        maxLength='50'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        required
                                        disabled={disabledField}
                                      />
                                      <SelectInput
                                        source='systematicDiscretionaryOption'
                                        label='Systematic Or Discretionary'
                                        options={
                                          systematicDiscretionaryOptions.options
                                        }
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />

                                      {/* Leverage */}

                                      <label className='font-weight-bold mb-2'>
                                        Use of Leverage
                                      </label>
                                      <div className='m-2'>
                                        <NullableBooleanInput
                                          style={{ width: '100%' }}
                                          source='leverage'
                                          label='Use of Leverage'
                                          disabled={disabledField}
                                        />
                                      </div>
                                      <SelectInput
                                        source='investmentCoActExemptionOption'
                                        label='Investment Co. Act of 1940 Exemption'
                                        options={
                                          investmentCoActExemptionOptions.options
                                        }
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />

                                      <SelectInput
                                        source='investmentAdvisorType'
                                        label='Registered Investment Advisor Type'
                                        options={investmentAdvisorTypes.options}
                                        validate={required}
                                        required={true}
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        m
                                        disabled={disabledField}
                                      />
                                      <FormDataConsumer
                                        dependsOn='investmentAdvisorType'
                                        resolve={(value) => value === 'STATE'}
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.investmentAdvisorType ===
                                            'STATE' && (
                                            <>
                                              <TextInput
                                                source='investmentAdvisorType_state'
                                                label='Name of the state'
                                                maxLength='50'
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <FormDataConsumer
                                        dependsOn='investmentAdvisorType'
                                        resolve={(value) => value === 'FOREIGN'}
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.investmentAdvisorType ===
                                            'FOREIGN' && (
                                            <>
                                              <p>
                                                Please describe the country of
                                                jurisdiction, regulatory agency,
                                                and type of registration
                                                received for the Fund and/or
                                                Manager
                                              </p>
                                              <TextInput
                                                source='foreign'
                                                label='Investment Advidor Type Foreign'
                                                maxLength='50'
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <FormDataConsumer
                                        dependsOn='investmentAdvisorType'
                                        resolve={(value) =>
                                          value === 'EXEMPTION'
                                        }
                                      >
                                        {({ formData, ...rest }) =>
                                          formData.investmentAdvisorType ===
                                            'EXEMPTION' && (
                                            <>
                                              <TextInput
                                                source='exemption'
                                                label='Describe Investment Advisor Registration Exemption'
                                                maxLength='50'
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                      <CurrencyInput
                                        source='aumfund'
                                        label='AUM Manager (USD)'
                                        validate={required}
                                        required
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabledField={disabledField}
                                      />
                                      <div className='mb-2'></div>
                                      <CurrencyInput
                                        source='fundCapacity'
                                        label='Fund Capacity (USD)'
                                        validate={required}
                                        required
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabledField={disabledField}
                                      />
                                      <AorNumberInput
                                        validate={required}
                                        required
                                        source='trackRecordFund'
                                        label='Track Record Fund mos'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />
                                      <AorNumberInput
                                        validate={required}
                                        required
                                        source='trackRecordFirm'
                                        label='Track Record Firm mos'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />
                                    </>
                                  )
                                );
                              }}
                            </FormDataConsumer>
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) =>
                                value !== 'SECONDARY_SALES' &&
                                value !== 'ACQUISITIONS_AND_DIVESTITURES' &&
                                value !== 'ASSET_SALES'
                              }
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType !== 'SECONDARY_SALES' &&
                                formData.listingType !==
                                  'ACQUISITIONS_AND_DIVESTITURES' &&
                                formData.listingType !== 'ASSET_SALES' && (
                                  <CurrencyInput
                                    source='targetRaise'
                                    label='Target Raise'
                                    validate={required}
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    required
                                    disabledField={disabledField}
                                  />
                                )
                              }
                            </FormDataConsumer>
                            <br />
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) => value === 'ASSET_SALES'}
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType === 'ASSET_SALES' && (
                                  <>
                                    <TextInput
                                      source='valueRange'
                                      label='Value Range'
                                      validate={required}
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      required
                                      disabled={disabledField}
                                    />
                                    <br />
                                  </>
                                )
                              }
                            </FormDataConsumer>
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) => value === 'SECONDARY_SALES'}
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType === 'SECONDARY_SALES' && (
                                  <>
                                    <SharesInput
                                      source='sharesForSale'
                                      label='Shares For Sale'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      validate={required}
                                      required
                                      disabledField={disabledField}
                                    />
                                    <br />
                                  </>
                                )
                              }
                            </FormDataConsumer>

                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) =>
                                value === 'ACQUISITIONS_AND_DIVESTITURES'
                              }
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType ===
                                  'ACQUISITIONS_AND_DIVESTITURES' && (
                                  <>
                                    <TextInput
                                      source='averageDailyProduction'
                                      label='Average Daily Production'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      validate={required}
                                      required
                                      disabled={disabledField}
                                    />
                                    <br />
                                  </>
                                )
                              }
                            </FormDataConsumer>
                            <br />
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) =>
                                value !== 'ACQUISITIONS_AND_DIVESTITURES' &&
                                value !== 'ASSET_SALES'
                              }
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType !==
                                  'ACQUISITIONS_AND_DIVESTITURES' &&
                                formData.listingType !== 'ASSET_SALES' &&
                                formData.listingType && (
                                  <CurrencyInput
                                    source='minimumInvestment'
                                    label='Minimum Investment'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={required}
                                    required
                                    disabledField={disabledField}
                                  />
                                )
                              }
                            </FormDataConsumer>
                            <br />
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) => value === 'ASSET_SALES'}
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType === 'ASSET_SALES' && (
                                  <TextInput
                                    source='assetStatus'
                                    label='Asset Status'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={required}
                                    required
                                    disabled={disabledField}
                                  />
                                )
                              }
                            </FormDataConsumer>
                            <br />
                            <FormDataConsumer
                              dependsOn='listingType'
                              resolve={(value) =>
                                value === 'ACQUISITIONS_AND_DIVESTITURES'
                              }
                            >
                              {({ formData, ...rest }) =>
                                formData.listingType ===
                                  'ACQUISITIONS_AND_DIVESTITURES' &&
                                formData.listingType && (
                                  // <TextInput
                                  //   source='netAcres'
                                  //   label='Net Acres'
                                  // />
                                  <SharesInput
                                    source='netAcres'
                                    label='Net Acres'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={required}
                                    required
                                    disabledField={disabledField}
                                  />
                                )
                              }
                            </FormDataConsumer>
                          </Grid>
                          <FormDataConsumer
                            dependsOn='listingType'
                            resolve={(value) => value !== 'FUNDS'}
                          >
                            {({ formData, ...rest }) =>
                              formData.listingType !== 'FUNDS' && (
                                <Grid item md={6}>
                                  <div className='mb-4'>
                                    <label className='font-weight-bold mb-2'>
                                      Sector Types
                                    </label>
                                    <SectorSelectInput
                                      source='sector'
                                      variant='outlined'
                                      fullWidth
                                      resource='offer'
                                      options={sectors.options}
                                      validate={required}
                                      disabled={disabledField}
                                    />
                                  </div>
                                </Grid>
                              )
                            }
                          </FormDataConsumer>
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>

                  <Container>
                    <ExpandSection name='7.  Highlights'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Highlights
                              </label>
                              <RichTextInput
                                source='highlights'
                                addLabel
                                variant='outlined'
                                fullWidth
                                resource='offer'
                                label='Highlights'
                                disabledField={disabledField}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>

                  <Container>
                    <ExpandSection name='8.  Offer Presentation'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Display Presentation
                              </label>
                              <div className='m-2'>
                                <NullableBooleanInput
                                  style={{ width: '100%' }}
                                  source='enableOfferPresentation'
                                  label='Display Presentation'
                                  disabled={disabledField}
                                />
                              </div>

                              <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                  formData.enableOfferPresentation && (
                                    <>
                                      <TextInput
                                        source='offerPresentationTitle'
                                        label='Offer Presentation Title'
                                        variant='outlined'
                                        fullWidth
                                        resource='offer'
                                        disabled={disabledField}
                                      />
                                      <label className='font-weight-bold mb-2'>
                                        Upload Presentation
                                      </label>
                                      <PDFInput
                                        source='offerPresentationUrl'
                                        addLabel
                                        label='Offer Presentation'
                                        aspect={4 / 3}
                                        recommendedAspect='recommended pdf file only'
                                        disabledField={disabledField}
                                      />
                                    </>
                                  )
                                }
                              </FormDataConsumer>
                            </div>
                          </Grid>{' '}
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>
                  <Container>
                    <ExpandSection name='9.  Documents Url'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Documents
                              </label>
                              <ArrayInput source='documents'>
                                <SimpleFormIterator>
                                  <TextInput
                                    source='title'
                                    label='Title'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={required}
                                    disabled={disabledField}
                                  />
                                  <TextInput
                                    source='url'
                                    label='URL'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={[required, url]}
                                    disabled={disabledField}
                                  />
                                  <label className='form-section-label'>
                                    Preview Image
                                  </label>
                                  <ImageInput
                                    source='previewImageId'
                                    label='Preview Image'
                                    aspect={15 / 7}
                                    recommendedAspect='recommended minimum image width: 100px'
                                    disabledField={disabledField}
                                  />
                                </SimpleFormIterator>
                              </ArrayInput>
                            </div>
                          </Grid>{' '}
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>

                  <Container>
                    <ExpandSection name="10.  Add Video Url's">
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                Video Url's
                              </label>

                              <ArrayInput
                                initialValue={[]}
                                source='videoFiles'
                                label=''
                              >
                                <SimpleFormIterator>
                                  <TextInput
                                    source='title'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    label='Title'
                                    validate={required}
                                    disabled={disabledField}
                                  />
                                  <TextInput
                                    source='url'
                                    label='URL'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={[required, url]}
                                    disabled={disabledField}
                                  />
                                  <label className='form-section-label'>
                                    Thumbnail Image
                                  </label>
                                  <ImageInput
                                    source='image'
                                    label='Thumbnail Image'
                                    aspect={4 / 3}
                                    recommendedAspect='recommended minimum image width: 300px'
                                    disabledField={disabledField}
                                  />
                                </SimpleFormIterator>
                              </ArrayInput>
                            </div>
                          </Grid>{' '}
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>
                  <Container>
                    <ExpandSection name='11. Frequently Asked Questions'>
                      <div className='py-4'>
                        {' '}
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <div className='mb-4'>
                              <label className='font-weight-bold mb-2'>
                                FAQ
                              </label>
                              <ArrayInput source='faqs' label=''>
                                <SimpleFormIterator>
                                  <TextInput
                                    source='question'
                                    label='Question'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={required}
                                    disabled={disabledField}
                                  />
                                  <TextInput
                                    source='answer'
                                    label='Answer'
                                    variant='outlined'
                                    fullWidth
                                    resource='offer'
                                    validate={required}
                                    disabled={disabledField}
                                  />
                                </SimpleFormIterator>
                              </ArrayInput>
                            </div>
                          </Grid>{' '}
                        </Grid>
                      </div>{' '}
                    </ExpandSection>
                  </Container>

                  <FormDataConsumer dependsOn='listingType'>
                    {({ formData, ...rest }) =>
                      formData.listingType &&
                      formData.listingType !==
                        'ACQUISITIONS_AND_DIVESTITURES' &&
                      formData.listingType !== 'ASSET_SALES' && (
                        <>
                          <Container>
                            <ExpandSection name='12. Investment Details'>
                              <div className='py-4'>
                                {' '}
                                <Grid container spacing={6}>
                                  <Grid item md={12}>
                                    <label className='font-weight-bold mb-2'>
                                      Investment Details
                                    </label>
                                    <div className='m-2'>
                                      <InvestmentBooleanInput
                                        source='enableInvestment'
                                        label='Enable Investment'
                                        disabled={disabledField}
                                      />
                                    </div>

                                    <div className='mb-4'>
                                      <br />
                                      <FormDataConsumer dependsOn='enableInvestment'>
                                        {({ formData, ...rest }) =>
                                          formData.enableInvestment && (
                                            <>
                                              <label className='font-weight-bold mb-2'>
                                                Docusign account required for
                                                OfferBoard Investments
                                              </label>
                                              <TextInput
                                                source='paymentDetails.subscriptionAgreementDocumentId'
                                                label='Subscription Agreement Doc ID'
                                                validate={required}
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                              <FormDataConsumer
                                                dependsOn='typeOfOffer'
                                                resolve={(value) =>
                                                  value === 'REG_A' || !value
                                                }
                                              >
                                                {({ formData, ...rest }) =>
                                                  (formData.typeOfOffer &&
                                                    formData.typeOfOffer ===
                                                      'REG_A') ||
                                                  (!formData.typeOfOffer && (
                                                    <TextInput
                                                      source='paymentDetails.brokerageAgreementDocumentId'
                                                      label='Brokerage Agreement Doc ID'
                                                      validate={required}
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  ))
                                                }
                                              </FormDataConsumer>
                                              <br />
                                              <TextInput
                                                source='paymentDetails.pricePerShareOrToken'
                                                label='Price Per Share Or Token'
                                                validate={required}
                                                normalize={onlyNumber}
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                required
                                                disabled={disabledField}
                                              />
                                              <br />
                                              <label className='font-weight-bold mb-2'>
                                                Accepted Payment Method
                                              </label>
                                              <br />
                                              <SelectArrayInput
                                                source='paymentDetails.acceptedPaymentMethod'
                                                label='Accepted Payment Method'
                                                options={paymentMethods.options}
                                                variant='outlined'
                                                fullWidth
                                                resource='offer'
                                                validate={required}
                                                disabledField={disabledField}
                                              />
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedPaymentMethod?.includes(
                                                    'BANK'
                                                  ) && (
                                                    <>
                                                      <br />
                                                      <label className='font-weight-bold mb-2'>
                                                        Accepted Bank Type
                                                      </label>
                                                      <SelectArrayInput
                                                        source='paymentDetails.acceptedBankTypes'
                                                        label='Accepted Bank Type'
                                                        choices={
                                                          bankPaymentTypes.options
                                                        }
                                                        allowEmpty={false}
                                                        validate={required}
                                                        required
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        disabledField={
                                                          disabledField
                                                        }
                                                        styleOptions={{
                                                          fullWidth: true,
                                                          listStyle: {
                                                            maxHeight: 250,
                                                          },
                                                          readOnly: true,
                                                        }}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.bankName'
                                                        label='Bank Name'
                                                        validate={required}
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.bankAddress'
                                                        label='Bank Address'
                                                        validate={required}
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.beneficiaryName'
                                                        label='Beneficiary Name'
                                                        validate={required}
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.swiftCode'
                                                        label='Swift Code'
                                                        validate={required}
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.accountNumber'
                                                        label='Account Number'
                                                        validate={required}
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.routingNumber'
                                                        label='Routing Number'
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        validate={required}
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                      <TextInput
                                                        source='paymentDetails.memoLine'
                                                        label='Memo Line'
                                                        variant='outlined'
                                                        fullWidth
                                                        resource='offer'
                                                        validate={required}
                                                        required
                                                        disabled={disabledField}
                                                      />
                                                    </>
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedPaymentMethod?.includes(
                                                    'CRYPTO'
                                                  ) && (
                                                    <>
                                                      <br />
                                                      <label className='font-weight-bold mb-2'>
                                                        Accepted Cryptocurrency
                                                        Type
                                                      </label>
                                                      <SelectArrayInput
                                                        source='paymentDetails.acceptedCryptoTypes'
                                                        label='Accepted Cryptocurrency Type'
                                                        choices={
                                                          cryptoPaymentTypes.options
                                                        }
                                                        allowEmpty={false}
                                                        validate={required}
                                                        required
                                                        disabledField={
                                                          disabledField
                                                        }
                                                        styleOptions={{
                                                          fullWidth: true,
                                                          listStyle: {
                                                            maxHeight: 200,
                                                          },
                                                          readOnly: true,
                                                        }}
                                                      />
                                                    </>
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_ETH'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.ethWalletAddress'
                                                      label='ETH Wallet Address'
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      validate={required}
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_BTC'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.btcWalletAddress'
                                                      label='BTC Wallet Address'
                                                      validate={required}
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_XLM'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.xlmWalletAddress'
                                                      label='XLM Wallet Address'
                                                      validate={required}
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_XRP'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.xrpWalletAddress'
                                                      label='XRP Wallet Address'
                                                      validate={required}
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_USDC'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.usdcWalletAddress'
                                                      label='USDC Wallet Address'
                                                      validate={required}
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_TUSD'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.tusdWalletAddress'
                                                      label='TUSD Wallet Address'
                                                      validate={required}
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                              <FormDataConsumer>
                                                {({ formData, ...rest }) =>
                                                  formData.paymentDetails &&
                                                  formData.paymentDetails.acceptedCryptoTypes?.includes(
                                                    'CRYPTO_PAX'
                                                  ) && (
                                                    <TextInput
                                                      source='paymentDetails.paxWalletAddress'
                                                      label='PAX Wallet Address'
                                                      variant='outlined'
                                                      fullWidth
                                                      resource='offer'
                                                      validate={required}
                                                      required
                                                      disabled={disabledField}
                                                    />
                                                  )
                                                }
                                              </FormDataConsumer>
                                            </>
                                          )
                                        }
                                      </FormDataConsumer>
                                    </div>
                                  </Grid>{' '}
                                </Grid>
                              </div>{' '}
                            </ExpandSection>
                          </Container>
                        </>
                      )
                    }
                  </FormDataConsumer>

                  <FormDataConsumer
                    dependsOn='sharedTenants'
                    resolve={(value) => value && value.length !== 0}
                  >
                    {({ formData, ...rest }) =>
                      formData.sharedTenants &&
                      formData.sharedTenants.length !== 0 && (
                        <>
                          <label className='font-weight-bold mb-2'>
                            This Offer has been shared with
                          </label>
                          <ArrayInput
                            source='sharedTenants'
                            label='You can choose to publish or unpublish this offer on the below tenant[s]'
                            allowRemove={false}
                            allowAdd={false}
                          >
                            <SimpleFormIterator>
                              <TextInput
                                source='name'
                                readOnly
                                variant='outlined'
                                fullWidth
                                resource='offer'
                                label='Tenant Name'
                                disabled={disabledField}
                              />
                              <NullableBooleanInput
                                style={{ width: '100%' }}
                                source='published'
                                label='Published'
                                disabled={disabledField}
                              />
                            </SimpleFormIterator>
                          </ArrayInput>
                        </>
                      )
                    }
                  </FormDataConsumer>
                  <Container>
                    <ExpandSection name='12. Request Offer Approval'>
                      <div className='py-4 d-flex flex-column'>
                        <span>Request Offer Approval</span>
                        <NullableBooleanInput
                          style={{ width: '100%' }}
                          resource='offer'
                          source='requestApprovalIssuer'
                          disabled={disabledField}
                        />
                      </div>
                    </ExpandSection>
                  </Container>
                  <div className='divider mt-5 mb-4' />
                  <Container className='d-flex align-items-center justify-content-between'>
                    <div className='py-4'>
                      {/* <SaveButton
                        saving={formProps.saving}
                        handleSubmitWithRedirect={
                          formProps.handleSubmitWithRedirect
                        }
                      /> */}
                    </div>
                    <div className='py-4'>
                      {/* <DeleteButton record={formProps.record} /> */}
                    </div>
                  </Container>
                </div>{' '}
              </div>
            </Card>
          </>
        </SimpleForm>
      )}
    />
  );
};
export default OfferForm;
