import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { get } from 'lodash';
import { SelectInput } from './select-input';
import apolloClient from 'apollo/apolloClient';
import { getTenant } from 'apollo';

const withTenantWrapper = (Component) => {
  const WrappedInput = (
    { availableListingTypes, options, ...otherProps },
    context
  ) => {
    const state = useSelector((state) => state.TenantReducer);
    const dispatch = useDispatch();

    const fetchData = async () => {
      if (!state.tenant) {
        try {
          const tenantId = JSON.parse(localStorage.getItem('issuer')).tenantId;
          const { data } = await apolloClient.query({
            query: getTenant,
            variables: { _id: tenantId },
          });
          dispatch({
            type: 'TENANT',
            payload: data.tenant,
          });
        } catch (error) {
          console.log('Error', error);
        }
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    const filterFrom =
      availableListingTypes || get(context, 'tenant.availableListingTypes', []);

    const filteredListingTypeOptions = state.tenant
      ? options.filter(({ value }) =>
          state.tenant.availableListingTypes.includes(value)
        )
      : options;

    return <Component {...otherProps} options={filteredListingTypeOptions} />;
  };

  WrappedInput.propTypes = {
    availableListingTypes: PropTypes.arrayOf(PropTypes.string),
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
  };

  WrappedInput.defaultProps = {
    label: undefined,
    options: undefined,
    availableListingTypes: undefined,
  };

  WrappedInput.contextTypes = {
    tenant: PropTypes.object,
  };

  return WrappedInput;
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('record-form');
  const tenantId = JSON.parse(localStorage.getItem('issuer')).tenantId;
  const availableListingTypes = get(
    state,
    `admin.resources.tenants.data[${tenantId}].availableListingTypes`
  );

  return {
    availableListingTypes,
  };
};

const ListingTypeSelectInput = withTenantWrapper(SelectInput);

export default ListingTypeSelectInput;
