import React, { useEffect } from 'react';
import './assets/base.scss';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';
import withContext from 'recompose/withContext'; // You should add recompose/withContext to your dependencies
import dataProvider from './providers/dataProviders';
import authProvider from './providers/authProvider';
import PageLogin from './components/AuthPage/PageLogin';
import GroupIcon from '@material-ui/icons/Group';
import { Resource, Admin } from 'react-admin';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from '@material-ui/icons/Person';
import { OfferList } from './components/Crud/Offer/OfferList';
import { OfferCreate } from './components/Crud/Offer/OfferCreate';
import { IssuerEdit } from './components/Crud/IssuerCompany/IsuerCompanyEdit';
import {
  ManagementTeamCreate,
  ManagementTeamEdit,
  ManagementTeamList,
} from './components/Crud/ManagementTeam/ManagementTeams';
import Layout from './Layout/BaseLayout';
import Dashboard from './components/Dashboard/Dashboard';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';
import {
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar,
} from '@fortawesome/free-regular-svg-icons';
import {
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink,
} from '@fortawesome/free-solid-svg-icons';
import customRoutes from 'customRoutes';
import { createTheme } from '@material-ui/core';
import { OfferEdit } from 'components/Crud/Offer/OfferEdit';
import { OfferShow } from 'components/Crud/Offer/OfferShow';
import UseAuthTimer from 'hooks/UseAuthTimer';
import { SESSION_IDLE_MINUTES } from 'config';
import apolloClient from 'apollo/apolloClient';
import { issuerCompany } from 'apollo';
import { useDispatch } from 'react-redux';

library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
);
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
);
library.add(
  fas,
  faExclamation,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faCheck,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
);
const history = createBrowserHistory();
const myTheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '20px 26px 20px 10px !important',
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: 'lightgray',
        '& > th ': {
          color: 'black',
          fontWeight: 'bold',
          fontSize: '1rem',
        },
      },
    },
  },
});

const App = () => {
  const dispatch = useDispatch();

  const getIssuerCompany = async () => {
    if (localStorage.getItem('authToken')) {
      const issuerId = JSON.parse(localStorage.getItem('issuer')).issuerCompany
        ._id;
      const { data } = await apolloClient.query({
        query: issuerCompany,
        variables: { id: issuerId },
      });

      return data.issuerCompany;
    }
  };

  useEffect(async () => {
    const data = await getIssuerCompany();
    dispatch({ type: 'ISSUER_COMPANY', payload: data });
  }, []);

  return (
    <>
      <UseAuthTimer timeout={+SESSION_IDLE_MINUTES} />
      <Admin
        history={history}
        theme={myTheme}
        customRoutes={customRoutes}
        layout={Layout}
        dashboard={Dashboard}
        loginPage={PageLogin}
        authProvider={authProvider}
        dataProvider={dataProvider}
        title='Issuer Portal'
      >
        <Resource
          name='managementTeam'
          options={{ label: 'Management Team' }}
          icon={GroupIcon}
          create={ManagementTeamCreate}
          edit={ManagementTeamEdit}
          list={ManagementTeamList}
        />
        <Resource
          name='changePassword'
          options={{ label: 'Change Password' }}
          icon={GroupIcon}
        />
        <Resource
          name='offers'
          options={{ label: 'Offers' }}
          list={OfferList}
          create={OfferCreate}
          show={OfferShow}
          edit={OfferEdit}
          icon={TrendingUpIcon}
        />

        <Resource
          name='documents'
          options={{ label: 'Documents' }}
          list={OfferList}
          create={OfferCreate}
          show={OfferShow}
          edit={OfferEdit}
          icon={TrendingUpIcon}
        />

        <Resource
          options={{ label: 'My Profile' }}
          name='issuerCompany'
          edit={IssuerEdit}
          icon={PersonIcon}
        />
        <Resource name='tenants' options={{ label: 'Tenant' }} />
      </Admin>
    </>
  );
};

export default withContext(
  {
    authProvider: PropTypes.object,
  },
  () => ({ authProvider })
)(App);
