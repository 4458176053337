import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { useHistory } from 'react-router-dom';

import logo from '../logo.png';
import { Grid, Container, Button, TextField } from '@material-ui/core';

import illustration1 from 'assets/images/illustrations/pack1/authentication.svg';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !email) {
      notify('password or email is required', 'error');
      return;
    }
    login({ email, password })
      .then((res) => {
        const { isDocumentSubmissionSystem } = JSON.parse(
          localStorage.getItem('issuer')
        ).issuerCompany;
        if (isDocumentSubmissionSystem) {
          history.push('/document-upload');
        } else history.push('/');
      })
      .catch((e) => notify(`${e}`, 'error'));
  };
  return (
    <>
      <div className='app-wrapper bg-white min-vh-100'>
        <div className='app-main min-vh-100'>
          <div className='app-content p-0'>
            <div className='app-content--inner d-flex align-items-center'>
              <div className='flex-grow-1 w-100 d-flex align-items-center'>
                <div className='bg-composed-wrapper--content py-5'>
                  <Notification />
                  <Container>
                    <Grid container spacing={6}>
                      <Grid item lg={6} className='d-flex align-items-center'>
                        <div className='divider-v d-none d-lg-block divider-v-md' />
                        <div className='w-100 pr-0 pr-lg-5'>
                          <div className='text-black mt-3'>
                            <span className='text-center'>
                              <h1 className='display-4 mb-1 font-weight-bold'>
                                Login to your account
                              </h1>
                              <p className='font-size-lg mb-0 text-black-50'>
                                Welcome Back
                              </p>
                            </span>
                            <form onSubmit={handleSubmit}>
                              <div>
                                <div className='mb-3'>
                                  <label className='font-weight-bold mb-2'>
                                    Email address
                                  </label>
                                  <TextField
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    placeholder='yourname@yourmail.com'
                                    name='email'
                                    type='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className='mb-4'>
                                  <div className='d-flex justify-content-between'>
                                    <label className='font-weight-bold mb-2'>
                                      Password
                                    </label>
                                    <a
                                      href='/forgot-password'
                                      // onClick={(e) => e.preventDefault()}
                                    >
                                      Reset password?
                                    </a>
                                  </div>
                                  <TextField
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    placeholder='Enter your password'
                                    name='password'
                                    type='password'
                                    value={password}
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>

                                <Button
                                  type='submit'
                                  size='large'
                                  fullWidth
                                  className='text-uppercase font-weight-bold font-size-sm btn-primary'
                                >
                                  Sign in
                                </Button>
                              </div>
                              <div className='text-center mt-5'>
                                <img
                                  alt='...'
                                  className='w-50 mx-auto d-block img-fluid'
                                  src={logo}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        className='d-none d-lg-flex align-items-center'
                      >
                        <img
                          alt='...'
                          className='w-100 mx-auto d-block img-fluid'
                          src={illustration1}
                        />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
