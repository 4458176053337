import * as React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { FieldTitle, useInput } from 'ra-core';

import sanitizeInputRestProps from './SanitizeProps';
import InputHelperText from './InputHelperText';

const BooleanInput = (props) => {
  const {
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    defaultvalue,
    ...rest
  } = props;
  const {
    id,
    input: { onChange: finalFormOnChange, type, value, ...inputProps },
    isRequired,
    meta: { error, submitError, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    type: 'checkbox',
    validate,
    ...rest,
  });

  const handleChange = useCallback(
    (event, value) => {
      finalFormOnChange(value);
    },
    [finalFormOnChange]
  );

  return (
    <FormGroup {...sanitizeInputRestProps(rest)}>
      <FormControlLabel
        control={
          <Switch
            id={id}
            onChange={handleChange}
            {...inputProps}
            {...options}
            label=''
            color='primary'
            className='switch-small'
            disabled={disabled}
            checked={defaultvalue}
          />
        }
      />
      <FormHelperText error={!!(error || submitError)}>
        <InputHelperText
          touched={touched}
          error={error || submitError}
          helperText={helperText}
        />
      </FormHelperText>
    </FormGroup>
  );
};

BooleanInput.propTypes = {
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.shape(Switch.propTypes),
  disabled: PropTypes.bool,
};

BooleanInput.defaultProps = {
  options: {},
};

export default BooleanInput;
