import React from 'react';

const SidebarFooter = () => {
  return (
    <>
      <div className='app-sidebar--footer'></div>
    </>
  );
};

export default SidebarFooter;
