import {
    SimpleForm as AorSimpleForm,
    showNotification,
    TabbedForm as AorTabbedForm,
} from 'react-admin';
import React from 'react';

const onSubmitFail = (error, dispatch) => {
    const missingRequired = Object.keys(error).some((field) => {
        const fieldError = error[field];

        if (fieldError === 'Required') {
            return true;
        }

        // Handle errors in embedded documents
        if (Array.isArray(fieldError)) {
            const embeddedDocuments = fieldError.filter(Boolean); // Remove undefined documents

            // eslint-disable-next-line no-restricted-syntax
            for (const embeddedDocument of embeddedDocuments) {
                const missingRequiredEmbedded = Object.keys(embeddedDocument).some(
                    (embeddedField) => embeddedDocument[embeddedField] === 'Required'
                );

                if (missingRequiredEmbedded) {
                    return true;
                }
            }
        }

        return false;
    });

    if (missingRequired) {
        dispatch(showNotification('Please fill in all required fields'));
    }
};

export const SimpleForm = (props) => (
    <AorSimpleForm onSubmitFail={onSubmitFail} {...props} />
);

export const TabbedForm = (props) => (
    <AorTabbedForm onSubmitFail={onSubmitFail} {...props} />
);
