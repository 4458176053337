import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './Redux/config/configureStore';
import { createBrowserHistory } from 'history';

import { Provider } from 'react-redux';
import App from './App';
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProviders';

import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

ReactDOM.render(
  <Provider
    store={configureStore({
      authProvider,
      dataProvider,
      history,
    })}
  >
    <App />
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
