import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useNotify } from 'react-admin';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from 'Redux/reducers/ThemeOptions';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import EmojiEventsTwoToneIcon from '@material-ui/icons/EmojiEventsTwoTone';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import BusinessIcon from '@mui/icons-material/Business';
import BadgeIcon from '@mui/icons-material/Badge';
import { useSelector } from 'react-redux';

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const [disableLink, setDisableLink] = useState();

  const notify = useNotify();

  const toggleSidebarMobile = (e) => {
    setSidebarToggleMobile(false);

    if (disableLink) {
      if (
        e.target.className.split(' ').includes('kyc') ||
        e.target.className.split(' ').includes('document')
      ) {
      } else {
        e.preventDefault();
        notify(
          'You must first upload all the required documents before you can be granted access',
          { type: 'error' }
        );
      }
    }
  };

  const issuerCompanyFromLocalstorage = JSON.parse(
    localStorage.getItem('issuer')
  );
  const issuerCompany =
    useSelector((state) => state?.IssuerCompanyReducer?.issuerCompany) ||
    issuerCompanyFromLocalstorage;

  const requiredUsersHasPerformedKyc = issuerCompany?.requiredKycUsers?.every(
    ({ performedKyc }) => performedKyc
  );

  useEffect(() => {
    if (
      (!issuerCompany?.corporateDocs ||
        !issuerCompany?.escrowDocs ||
        !issuerCompany?.executiveSummaryDocs ||
        !issuerCompany?.faqsDocs ||
        !issuerCompany?.financialDocs ||
        !issuerCompany?.investorSlideDeckDocs ||
        !issuerCompany?.managementDocs ||
        !issuerCompany?.privatePlacementDocs ||
        !issuerCompany?.riskMatrixDocs ||
        !issuerCompany?.subscriptionAgreementDocs ||
        !issuerCompany?.termSheetDocs ||
        !requiredUsersHasPerformedKyc) &&
      issuerCompany?.isDocumentSubmissionSystem
    ) {
      setDisableLink(true);
    } else {
      setDisableLink(false);
    }
  }, []);

  return (
    <>
      <PerfectScrollbar>
        <div className='sidebar-navigation'>
          <div className='sidebar-header'>
            <span>Navigation</span>
          </div>

          <ul>
            {issuerCompany?.isDocumentSubmissionSystem && (
              <li>
                <NavLink
                  onClick={toggleSidebarMobile}
                  activeClassName='active'
                  className='nav-link-simple document'
                  to='/document-upload'
                >
                  <span className='sidebar-icon'>
                    <CloudUploadIcon />
                  </span>
                  Documents
                  <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                    <ChevronRightTwoToneIcon />
                  </span>
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName='active'
                className='nav-link-simple kyc'
                to='/kyc'
              >
                <span className='sidebar-icon'>
                  <BadgeIcon />
                </span>
                KYC
                <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName='active'
                className='nav-link-simple'
                to='/'
                exact
              >
                <span className='sidebar-icon'>
                  <EmojiEventsTwoToneIcon />
                </span>
                Dashboard
                <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName='active'
                className='nav-link-simple'
                to='/offers'
              >
                <span className='sidebar-icon'>
                  <AttachMoneyTwoToneIcon />
                </span>
                Offers
                <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName='active'
                className='nav-link-simple'
                to='/managementTeam'
              >
                <span className='sidebar-icon'>
                  <BusinessCenterTwoToneIcon />
                </span>
                Management Team
                <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName='active'
                className='nav-link-simple'
                to={`${'/issuerCompany/'}${
                  JSON.parse(localStorage.getItem('issuer'))?._id
                }`}
              >
                <span className='sidebar-icon'>
                  <BusinessIcon />
                </span>
                Company Profile
                <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
