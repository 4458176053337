/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import './react-draft-wysiwyg.css';
import { CLOUDINARY_UPLOAD_PRESET } from '../config';
const stripParagraphs = (html) =>
  html ? html.replace(/<\/?p[^>]*>/g, '').trim() : null;

const richTextRequired = (html) => {
  const rawText = stripParagraphs(html);

  return rawText ? undefined : 'Required';
};

class RichTextInput extends Component {
  constructor(props) {
    super(props);

    const contentBlock = htmlToDraft(props.input.value);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      this.state = {
        editorState,
      };
    }
  }
  convertImages = (htmlText) => {
    const regex = /<img\s[^>]*?style\s*=\s*['\"]float([^'\"]*?)['\"][^>]*?>/g;
    let m;
    while ((m = regex.exec(htmlText)) !== null) {
      if (m.index === regex.lastIndex) regex.lastIndex++;
      let repl = null,
        type = null;
      m.forEach((match, groupIndex) => {
        if (groupIndex == 0) repl = match;
        if (groupIndex == 1) type = match;
        if (repl && type) {
          if (type.includes('none'))
            htmlText = htmlText.replace(
              repl,
              `<div class="imageWrap" >` + repl + '</div>'
            );
          else
            htmlText = htmlText.replace(
              repl,
              `<div  class="imageWrap" >` + repl + '</div>'
            );
          repl = null;
          type = null;
        }
      });
    }
    return htmlText;
  };
  convertToHtml = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawContentState);
    let htmlConverted = this.convertImages(html);
    return stripParagraphs(htmlConverted) ? htmlConverted : null;
  };

  handleEditorStateChange = (editorState) => {
    const { onBlur, onChange } = this.props.input;
    const html = this.convertToHtml(editorState);

    this.setState({ editorState });
    onBlur();
    onChange(html);
  };
  uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

      fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
          },
          body: formData,
        }
      )
        .then((response) => response.json())
        .then((result) => {
          const link = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/q_100/${result.public_id}`;

          resolve({ data: { link: link } });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  render() {
    const { editorState } = this.state;
    const {
      meta: { touched, error },
      disabledField,
    } = this.props;

    return (
      <div>
        <Editor
          toolbar={{
            options: disabledField
              ? []
              : [
                  'image',
                  'list',
                  'textAlign',
                  'link',
                  'inline',
                  'fontSize',
                  'fontFamily',
                  'colorPicker',
                  'history',
                ],
            inline: {
              options: disabledField
                ? []
                : ['bold', 'italic', 'underline', 'strikethrough'],
            },

            image: {
              uploadCallback: this.uploadCallback,
              previewImage: true,
              alt: { present: true, mandatory: false },
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
            },
          }}
          readOnly={disabledField}
          editorState={editorState}
          onEditorStateChange={this.handleEditorStateChange}
          wrapperClassName='rich-editor-wrapper'
          editorClassName='rich-editor'
        />
        {touched && error ? (
          <p style={{ fontSize: '13px', color: '#F44336' }}>{error}</p>
        ) : null}
      </div>
    );
  }
}

const FieldWrapper = ({ source, validate, disabledField }) => (
  <Field
    name={source}
    disabledField={disabledField}
    component={RichTextInput}
    validate={validate}
  />
);

RichTextInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

FieldWrapper.propTypes = {
  source: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

FieldWrapper.defaultProps = {
  validate: null,
  disabledField: false,
};

export { FieldWrapper as RichTextInput, richTextRequired };

export default RichTextInput;
