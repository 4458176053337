import gql from 'graphql-tag';

export default gql`
  query dashboardQuery {
    investments(query: { limit: 0, sort: "order", order: ASC }) {
      items {
        id
        investmentAmount
        investmentAmountCrypto
        investmentStatus
        createdAt
        offerName
        paymentType
        paymentSent
        investorName
        offer {
          visibility
        }
        investorUser {
          firstName
          lastName
        }
        paymentMethod
      }
    }
  }
`;
