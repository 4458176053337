import React from 'react';
import OfferCreateEditForm from './Form/OfferForm';
import { Create } from 'react-admin';
export const OfferCreate = (props) => (
  <Create {...props}>
    <OfferCreateEditForm
      //  permissions={permissions}
      redirect='list'
      name='Create Offer'
      allowEmpty
    />
  </Create>
);
