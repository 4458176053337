import managementTeam from './managementTeamConfig'
import issuerCompany from './issuerCompanyConfig'
import offers from './offersConfig';
import tenants from './tenantsConfig';
const restConfig = {
    managementTeam,
    issuerCompany,
    offers,
    tenants
}

export default restConfig;