import { gql } from '@apollo/client';

export default gql`
  mutation issuerKycRequest($input: IssuerKycRequestInput!) {
    issuerKycRequest(input: $input) {
      _id
      firstName
      lastName
      kycTransactionId
    }
  }
`;
