// https://marmelab.com/admin-on-rest/RestClients.html#writing-your-own-rest-client
// Transform REST params into data structure used for GraphQL type QueryInput
const restParamsToQueryInput = (restParams) => {
    const { pagination, sort, id } = restParams;

    const { page, perPage } = pagination;
    const { q, ...filter } = restParams.filter;

    const query = {
        skip: (page - 1) * perPage,
        limit: perPage,
        // Convert "id" to "_id" for Mongo
        sort: sort.field === 'id' ? '_id' : sort.field,
        order: sort.order,
        search: q, // Search param will be handled differently
        filter,
    };

    // Convert "id" to "_id" for Mongo
    const target = restParams.target === 'id' ? '_id' : restParams.target;

    // These fields are used for relational data (e.g., target: "_id", id: "123")
    // If available, add them as another filter
    if (target && id) {
        query.filter = {
            ...query.filter,
            [target]: id,
        };
    }

    return { query };
};

export default restParamsToQueryInput;
