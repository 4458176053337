const GET_LIST = {
  query: `
        query($query: QueryInput) {
          issuerCompanies(query: $query) {
            items {
              id
              tenantId
              name
              logoId
              address
              city
              state
              postalCode
              contactName
              officePhoneNumber
              mobilePhoneNumber
              employerIdNumber
              companyBackground
              isCompanyApproved
              isDocumentSubmissionSystem
            }
            total
          }
        }
      `,
  parseResult: ({ data }) => ({
    data: data.issuerCompanies.items,
    total: data.issuerCompanies.total,
  }),
};

const issuersConfig = {
  GET_LIST,

  GET_MANY: GET_LIST,

  GET_ONE: {
    query: `
        query($id: ID!) {
          issuerCompany(_id: $id) {
            id
            tenantId
            name
            logoId
            address
            city
            state
            postalCode
            contactName
            officePhoneNumber
            mobilePhoneNumber
            employerIdNumber
            companyBackground
            email
            isCompanyApproved
            isDocumentSubmissionSystem
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.issuerCompany }),
  },

  CREATE: {
    mutation: `
        mutation($data: IssuerCompanyInput!) {
          createIssuerCompany(data: $data) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.createIssuerCompany }),
  },

  UPDATE: {
    mutation: `
        mutation($id: ID!, $data: IssuerCompanyInput!) {
          updateIssuerCompany(_id: $id, data: $data) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.updateIssuerCompany }),
  },

  DELETE: {
    mutation: `
        mutation($id: ID!) {
          removeIssuerCompany(_id: $id) {
            id
          }
        }
      `,
    parseResult: ({ data }) => ({ data: data.removeIssuerCompany }),
  },
};

export default issuersConfig;
