import React, { Component } from 'react';
import { AuthTimer } from 'entoro-shared';
import PropTypes from 'prop-types';

class UseAuthTimer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.timeout = 1000 * 60 * props.timeout;
  }
  handleOnIdle = () => {
    this._logout();
  };

  _logout = () => {
    // Remove the token from local storage
    localStorage.removeItem('authToken');
    localStorage.removeItem('userType');

    // redirect to login page
    window.location.href = '/login';
  };
  render() {
    return (
      <AuthTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        onIdle={this.handleOnIdle}
        timeout={this.timeout}
        crossTab={{
          type: 'localStorage',
          emitOnAllTabs: true,
        }}
        debounce={250}
      />
    );
  }
}
UseAuthTimer.propTypes = {
  timeout: PropTypes.number.isRequired,
};

export default UseAuthTimer;
