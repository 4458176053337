import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormState } from 'react-final-form';
import { get } from 'lodash';
import BooleanInput from './TenantBooleanInput';

const withTenantWrapper = (Component) => {
  const WrappedInput = ({ ...otherProps }, context) => {
    const formState = useFormState();

    let tenantId = formState.values['tenantId'];

    const docusign = get(
      formState.values,
      `admin.resources.tenants.data[${tenantId}].docusign`
    );
    const hasDocusign = docusign || get(context, 'tenant.docusign');

    return <Component {...otherProps} options={{ disabled: !hasDocusign }} />;
  };

  WrappedInput.propTypes = {
    docusign: PropTypes.object,
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
  };

  WrappedInput.defaultProps = {
    label: undefined,
    docusign: null,
  };

  WrappedInput.contextTypes = {
    tenant: PropTypes.object,
  };

  return WrappedInput;
};

const InvestmentBooleanInput = withTenantWrapper(BooleanInput);

export default connect(null)(InvestmentBooleanInput);
