import {
  SaveButton,
  Toolbar,
  Edit,
  ReferenceField,
  SimpleForm,
  TextInput,
  TextField,
} from 'react-admin';
//import RichTextInput from 'ra-input-rich-text';
import { required } from '../../../utility/validate';

import { usaStates } from 'entoro-shared';
import React from 'react';
import {
  PhoneNumberInput,
  validatePhone,
} from '../../../utility/PhoneNumberInput';
import ImageInput from '../../../utility/ImageInput';
import { RichTextInput, richTextRequired } from '../../../utility/RicTextInput';
import { SelectInput } from '../../../utility/SelectInput';
import { makeStyles } from '@material-ui/core';

const UserEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFilledInput-input': {
      backgroundColor: 'white',
      border: '1px solid grey',
      borderRadius: '5px',
    },
  },
}));

export const IssuerEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props} title='My Profile'>
      <SimpleForm toolbar={<UserEditToolbar />} redirect='/' {...props}>
        <ReferenceField
          // basePath='/tenants'
          source='tenantId'
          reference='tenants'
          label='Tenant'
        >
          <TextField source='name' />
        </ReferenceField>

        <TextInput className={classes.root} source='name' validate={required} />
        <ImageInput
          source='logoId'
          label='Logo'
          recommendedAspect='recommended minimum image width: 100px'
          validate={required}
          addLabel
        />

        <TextInput className={classes.root} source='address' />
        <TextInput className={classes.root} source='city' />
        <SelectInput
          className={classes.root}
          source='state'
          options={usaStates.options}
          allowEmpty
        />
        <TextInput className={classes.root} source='postalCode' label='Zip' />
        <TextInput
          className={classes.root}
          source='contactName'
          label='Contact Name'
        />
        <PhoneNumberInput
          className={classes.root}
          source='officePhoneNumber'
          addLabel
          label='Office Phone Number'
          validate={validatePhone}
        />
        <PhoneNumberInput
          className={classes.root}
          source='mobilePhoneNumber'
          addLabel
          label='Mobile Phone Number'
          validate={validatePhone}
        />
        <TextInput
          className={classes.root}
          source='employerIdNumber'
          label='Employer ID Number'
        />
        <TextInput
          className={classes.root}
          source='email'
          label='Email Address'
        />
        <RichTextInput
          source='companyBackground'
          addLabel
          className={classes.root}
          label='Company Summary'
          validate={richTextRequired}
        />
      </SimpleForm>
    </Edit>
  );
};
