import React from 'react';

// react admin component
import {
  TextInput,
  FormWithRedirect,
  SaveButton,
  DeleteButton,
  NullableBooleanInput,
} from 'react-admin';

// utils
import { RichTextInput } from 'utility/RicTextInput';
import { required } from 'utility/validate';
import ImageInput from 'utility/ImageInput';

// mui
import { Container, Card, Grid } from '@material-ui/core';

const ManagementTeamForm = ({
  allowEmpty,
  permissions,
  disabledField,
  ...props
}) => {
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        // here starts the custom form layout
        <form>
          <>
            <Card>
              <div className='card-header d-flex align-items-center justify-content-between card-header-alt p-0'></div>
              <div className='px-0 py-0 py-lg-4'>
                <div index={1}>
                  {!disabledField && (
                    <>
                      <Container>
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <label className='font-weight-bold mb-2'>
                              Disabled Issuer
                            </label>
                            <div className='m-2'>
                              <NullableBooleanInput
                                style={{ width: '100%' }}
                                source='isUserDisabled'
                                label='Disable Issuer User'
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                      <Container>
                        <Grid container spacing={6}>
                          <Grid item md={12}>
                            <label className='font-weight-bold mb-2'>
                              Send invite to issuer portal
                            </label>
                            <div className='m-2'>
                              <NullableBooleanInput
                                style={{ width: '100%' }}
                                source='sendInvite'
                                label='Send Invite'
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </Container>
                    </>
                  )}
                  <Container>
                    <div className='text-uppercase font-weight-bold text-primary pt-4 font-size-sm'>
                      General
                    </div>
                    <div className='py-4'>
                      <Grid container spacing={6}>
                        <Grid item md={6}>
                          <div className='mb-4'>
                            <label className='font-weight-bold mb-2'>
                              First Name
                            </label>
                            <TextInput
                              variant='outlined'
                              fullWidth
                              source='firstName'
                              resource='firstName'
                              validate={required}
                              placeholder='First name...'
                              disabled={disabledField}
                            />
                          </div>
                          <label className='font-weight-bold mb-2'>Email</label>
                          <div className='mb-4'>
                            <TextInput
                              variant='outlined'
                              fullWidth
                              source='email'
                              resource='email'
                              validate={required}
                              placeholder='Email...'
                              disabled={disabledField}
                            />
                          </div>{' '}
                        </Grid>
                        <Grid item md={6}>
                          <div className='mb-4'>
                            <label className='font-weight-bold mb-2'>
                              Last Name
                            </label>
                            <TextInput
                              variant='outlined'
                              fullWidth
                              source='lastName'
                              label='Last Name'
                              validate={required}
                              placeholder='Last name...'
                              disabled={disabledField}
                            />
                          </div>
                          <label className='font-weight-bold mb-2'>
                            Position
                          </label>
                          <div className='mb-4'>
                            <TextInput
                              variant='outlined'
                              fullWidth
                              source='position'
                              label='Member Position'
                              validate={required}
                              placeholder='Position...'
                              disabled={disabledField}
                            />
                          </div>{' '}
                        </Grid>
                      </Grid>
                    </div>
                  </Container>
                  <div className='divider mb-4' />
                  <Container>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <div className='mb-4'>
                          <label className='font-weight-bold mb-2'>Image</label>
                          <ImageInput
                            source='imageId'
                            accept='image/*'
                            addLabel
                            label='Image'
                            aspect={1 / 1}
                            recommendedAspect='recommended minimum image width: 200px'
                            disabled={disabledField}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                  <Container>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <div className='mb-4'>
                          <label className='font-weight-bold mb-2'>
                            Biograph
                          </label>
                          <RichTextInput
                            source='biography'
                            resource='biography'
                            addLabel
                            label='Biography'
                            disabledField={disabledField}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Container>

                  {!disabledField && (
                    <>
                      <div className='divider mt-5 mb-4' />
                      <Container className='d-flex align-items-center justify-content-between'>
                        <div className='py-4'>
                          <SaveButton
                            saving={formProps.saving}
                            handleSubmitWithRedirect={
                              formProps.handleSubmitWithRedirect
                            }
                          />
                        </div>
                        <div className='py-4'>
                          <DeleteButton record={formProps.record} />
                        </div>
                      </Container>
                    </>
                  )}
                </div>{' '}
              </div>
            </Card>
          </>
        </form>
      )}
    />
  );
};
export default ManagementTeamForm;
