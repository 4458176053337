import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import {
  formatCurrency,
  formatCryptoCurrency,
  paymentTypes,
} from 'entoro-shared';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
    '& thead th': {
      color: '#0f0f0f',
      backgroundColor: '#f1f1f7',
    },
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#fffbf2',
      cursor: 'pointer',
    },
  },
  progressspan: {
    background: 'rgba(255, 83, 74, 0.3)',
    color: '#FF534A',
    borderRadius: 2,
    width: 100,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 3,
  },
  pendingspan: {
    background: 'rgba(255, 206, 55, 0.3)',
    color: '#FFCE30',
    borderRadius: 2,
    width: 100,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 3,
  },
  pageContent: {
    margin: 25,
    padding: theme.spacing(3),
    width: '100%',
    top: 25,
  },
  responsiveTable: {
    overflowX: 'auto',
    background: 'rgb(177 177 177 / 15%)',
  },
  btn: {
    background: 'rgba(84, 180, 27, 0.3)',
    color: '#54B41B',
    borderRadius: 2,
    width: 100,
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: 3,
  },
  noName: {
    textDecoration: 'line-through',
    color: 'palevioletred',
    textDecorationColor: 'black',
    textDecorationThickness: '2px',
  },
}));

const RecentInvestment = (props) => {
  const { investments } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={6}>
      <Paper className={classes.pageContent} elevation={0}>
        <div style={{ color: '#3f51b5', fontSize: '25px' }}>
          Investors Activity
        </div>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>Investor Name</TableCell>
              <TableCell>Offer Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Investment Status</TableCell>
              <TableCell>Investment Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {investments.items
              .slice(0, 7)
              .reverse()
              .map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{++i}</TableCell>

                  <TableCell component='th' scope='row'>
                    {row.investorUser.firstName === 'null' ? (
                      <span className={classes.noName}>NO NAME</span>
                    ) : (
                      `${row.investorName}`
                    )}
                  </TableCell>
                  <TableCell>
                    {row.offerName === '' ? (
                      <span className={classes.noName}>NO OFFER NAME</span>
                    ) : (
                      row.offerName
                    )}
                  </TableCell>
                  <TableCell>
                    {row.paymentType.includes('CRYPTO')
                      ? formatCryptoCurrency(row.investmentAmountCrypto)
                      : formatCurrency(row.investmentAmount)}
                  </TableCell>
                  <TableCell>
                    {paymentTypes.getLabel(row.paymentType)}
                  </TableCell>
                  <TableCell>
                    <span
                      className={` ${row.investmentStatus === 'IN_PROGRESS'
                          ? classes.progressspan
                          : ''
                        } ${row.investmentStatus === 'INVESTMENT_COMPLETE'
                          ? classes.btn
                          : ''
                        } ${row.investmentStatus ===
                          'PENDING_PAYMENT_VERIFICATION' ||
                          row.investmentStatus === 'PENDING_WALLET_ADDRESS' ||
                          row.investmentStatus === 'PENDING_TRANSACTION_RECEIPT'
                          ? classes.pendingspan
                          : ''
                        }`}
                    >
                      {row.investmentStatus ===
                        'PENDING_PAYMENT_VERIFICATION' ||
                        row.investmentStatus === 'PENDING_WALLET_ADDRESS' ||
                        row.investmentStatus === 'PENDING_TRANSACTION_RECEIPT'
                        ? 'Pending'
                        : ''}
                      {row.investmentStatus === 'IN_PROGRESS' && 'In Progress'}
                      {row.investmentStatus === 'INVESTMENT_COMPLETE' &&
                        'Completed'}
                    </span>
                  </TableCell>
                  <TableCell>
                    {moment.utc(new Date(row.createdAt)).format('MM/DD/YYYY')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </Grid>
  );
};

RecentInvestment.propTypes = {
  investments: PropTypes.array,
};

export default RecentInvestment;
