const getErrorMessage = (error) => {
    if (error instanceof Error) {
        let displayedMessage;

        const { graphQLErrors = [], message } = error;
        const [firstGraphqlError] = graphQLErrors;

        if (firstGraphqlError) {
            displayedMessage = firstGraphqlError.message;
        } else {
            displayedMessage = message;
        }

        return displayedMessage;
    }
    return null;
};

export default getErrorMessage;
