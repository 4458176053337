import { gql } from '@apollo/client';

export default gql`
  query($id: ID!) {
    issuerCompany(_id: $id) {
      id
      tenantId
      name
      logoId
      address
      city
      state
      postalCode
      contactName
      officePhoneNumber
      mobilePhoneNumber
      employerIdNumber
      companyBackground
      email
      isCompanyApproved
      isDocumentSubmissionSystem
      corporateDocs
      managementDocs
      financialDocs
      escrowDocs
      executiveSummaryDocs
      faqsDocs
      investorSlideDeckDocs
      privatePlacementDocs
      riskMatrixDocs
      subscriptionAgreementDocs
      termSheetDocs
      requiredKycUsers {
        issuerAdminId
        issuerAdminName
        issuerAdminEmail
        createdAt
        performedKyc
      }
    }
  }
`;
