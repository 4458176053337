const initialState = {
  issuerCompany: {},
};

export const IssuerCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ISSUER_COMPANY':
      return { ...state, issuerCompany: action.payload };
    default:
      return state;
  }
};
