import { isValidNumber } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React from 'react';
import Phone from 'react-phone-number-input';
import { Field } from 'react-final-form';
//import 'react-phone-number-input/rrui.css';
import 'react-phone-number-input/style.css';
import 'entoro-shared/src/styles/react-phone-number-input.css';

const validatePhone = (value) => {
  if (!value) {
    return undefined;
  }

  return isValidNumber(value) ? undefined : 'Invalid phone number';
};

const inputStyles = {
  color: 'rgba(0, 0, 0, 0.87)',
  display: 'inline-flex',
  position: 'relative',
  alignItems: 'baseline',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  marginTop: '8px',
};

const PhoneNumberInput = ({
  input: { value, onBlur, onChange },
  meta: { touched, error },
}) => (
  <div>
    <Phone
      country='US'
      // flagComponent={({ countryCode }) => <span>{countryCode}</span>}
      style={inputStyles}
      selectStyle={{ height: '32px' }}
      inputStyle={{ height: '32px' }}
      className='phone-input-field phone-input-field-admin'
      placeholder='Enter phone number'
      value={value}
      onChange={(val) => {
        onBlur();
        onChange(val);
      }}
    />
    {touched && error ? (
      <p style={{ fontSize: '13px', color: '#F44336' }}>{error}</p>
    ) : null}
  </div>
);

const FieldWrapper = ({ source, validate }) => (
  <Field name={source} component={PhoneNumberInput} validate={validate} />
);

PhoneNumberInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

FieldWrapper.propTypes = {
  source: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

FieldWrapper.defaultProps = {
  validate: null,
};

export { FieldWrapper as PhoneNumberInput, validatePhone };

export default PhoneNumberInput;
