import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();
export const GRAPHQL_URI =
  env.REACT_APP_GRAPHQL_URI || 'http://localhost:4000/admin-graphql';

export const CONTACT_EMAIL_DEFAULT =
  env.CONTACT_EMAIL_DEFAULT || 'support@offerboard.com';
export const CLOUDINARY_CLOUD_NAME =
  env.REACT_APP_CLOUDINARY_CLOUD_NAME || 'entoro-dev';
export const CLOUDINARY_UPLOAD_PRESET =
  env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || 'unsigned-upload';
// export const GRAPHQL_URI = env.REACT_APP_GRAPHQL_URI || 'http://localhost:4000/rest';
export const RESTAPI_URI = env.REACT_APP_RESTAPI_URI;
export const LIST_ITEMS_PER_PAGE =
  Number(env.REACT_APP_LIST_ITEMS_PER_PAGE) || 15;
export const REF_FIELD_MAX = Number(env.REACT_APP_REF_FIELD_MAX) || 100;
export const SESSION_IDLE_MINUTES = env.REACT_APP_SESSION_IDLE_MINUTES || 60;
